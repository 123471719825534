import {
  Alignment,
  CalendarIcon,
  Meta,
  Text,
  variants,
} from '@resident-advisor/design-system'
import { toDateFormat } from 'lib/dateFormat'
import isMultiDayEvent from 'lib/isMultiDayEvent'
import DateComponent, { formats } from '@/components/generic/date'

const EventDateRange = ({
  startTime,
  endTime,
  startTestId,
  endTestId,
  variant,
}: EventDateRangeMetaProps) => {
  const formattedStartTime = toDateFormat(startTime)
  const formattedEndTime = toDateFormat(endTime)

  if (isMultiDayEvent(formattedStartTime, formattedEndTime)) {
    return (
      <time
        dateTime={`${formattedStartTime?.toISOString()}/${formattedEndTime?.toISOString()}`}
      >
        <Alignment flexWrap="wrap">
          <Alignment flexWrap="no-wrap">
            <DayMonthAndYear date={formattedStartTime} variant={variant} />
            <Time
              date={formattedStartTime}
              testId={startTestId}
              variant={variant}
            />
            <Text variant={variant}>&nbsp;-&nbsp;</Text>
          </Alignment>
          <Alignment flexWrap="no-wrap">
            <DayMonthAndYear date={formattedEndTime} variant={variant} />
            <Time
              date={formattedEndTime}
              variant={variant}
              testId={endTestId}
            />
          </Alignment>
        </Alignment>
      </time>
    )
  }

  return (
    <time
      dateTime={`${formattedStartTime?.toISOString()}/${formattedEndTime?.toISOString()}`}
    >
      <Alignment flexWrap="no-wrap">
        <DayMonthAndYear date={formattedStartTime} variant={variant} />
        <Time
          date={formattedStartTime}
          testId={startTestId}
          variant={variant}
        />
        <Text variant={variant}>&nbsp;-&nbsp;</Text>
        <Time date={formattedEndTime} variant={variant} testId={endTestId} />
      </Alignment>
    </time>
  )
}

const EventDateRangeMeta = (props: EventDateRangeMetaProps) => (
  <Meta Icon={CalendarIcon} pr={3}>
    <EventDateRange variant={variants.text.tag} {...props} />
  </Meta>
)

const DayMonthAndYear = ({
  date,
  variant,
}: {
  date: Date
  variant: string
}) => (
  <DateComponent
    uppercase={false}
    variant={variant}
    date={date}
    format={formats.DayMonthAndYear}
    pr={{ m: 2, s: 1 }}
  />
)

const Time = ({
  date,
  variant,
  testId,
}: {
  date: Date
  variant: string
  testId?: string
}) => (
  <DateComponent
    uppercase={false}
    variant={variant}
    date={date}
    format={formats.Time}
    data-testid={testId}
  />
)

type EventDateRangeMetaProps = {
  startTime: Date | string
  endTime: Date | string
  startTestId?: string
  endTestId?: string
  variant?: string
}

export { EventDateRangeMeta }
export default EventDateRange
