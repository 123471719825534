import { useState } from 'react'
import {
  Alignment,
  variants,
  Button,
  Box,
  colors,
} from '@resident-advisor/design-system'
import styled from 'styled-components'
import {
  BrunchTicketType,
  SelectedTicketType,
  stringToBrunchTicketType,
} from '../helpers/BrunchTicketTypes'
import ButtonLink from '@/components/generic/button-link'
import PLAYWRIGHT_TEST_IDS from '@/enums/playwright-test-ids'
import animation from '@/themes/animation'
import Underline from '@/components/generic/sub-nav/Underline'
import { SubNavScrollArea } from '@/components/generic/sub-nav/SubNav'
import {
  BrunchPassTypes,
  getBrunchPassType,
  getBrunchTicketTypesForPass,
} from '../helpers/BrunchPasses'
import getBrunchTicketTypeTitle from '../helpers/BrunchTicketTypeTitles'

const BrunchTicketFilter = ({
  ticketType,
  isMobile = false,
  onFilterChange,
}: {
  ticketType: BrunchTicketType
  isMobile: boolean
  onFilterChange: (updatedTicketType: SelectedTicketType) => void
}) => {
  const [selectedPassType, setSelectedPassType] = useState<number>(
    getBrunchPassType(ticketType)
  )

  const handleClick = (passType: BrunchTicketType) => {
    setSelectedPassType(getBrunchPassType(passType))

    const ticketTypes = stringToBrunchTicketType(passType)
    if (ticketTypes) onFilterChange(ticketTypes)
  }

  return (
    <div data-pw-test-id={PLAYWRIGHT_TEST_IDS.ticketFilter}>
      <Alignment flexDirection="row" overflow="hidden" mb={3}>
        <ButtonLink
          onClick={() => {
            handleClick(BrunchTicketType.THURSDAY)
          }}
          variant={variants.text.body}
          mr={3}
          data-pw-test-id={PLAYWRIGHT_TEST_IDS.ticketPassOption}
          key="day-passes"
          color={
            selectedPassType === BrunchPassTypes.day ? 'primary' : 'tertiary'
          }
          hoverColor={isMobile ? 'primary' : 'accent'}
          data-testid="pass-button"
        >
          Single day tickets
          {selectedPassType === BrunchPassTypes.day && (
            <StyledUnderlined
              layoutId="underline"
              transition={animation.default.closed}
              data-testid="underline"
            />
          )}
        </ButtonLink>
        <ButtonLink
          onClick={() => {
            handleClick(BrunchTicketType.THREE_DAY)
          }}
          variant={variants.text.body}
          mr={3}
          data-pw-test-id={PLAYWRIGHT_TEST_IDS.ticketPassOption}
          key="weekend-passes"
          color={
            selectedPassType === BrunchPassTypes.weekend
              ? 'primary'
              : 'tertiary'
          }
          hoverColor={isMobile ? 'primary' : 'accent'}
          data-testid="pass-button"
        >
          Multiday pass
          {selectedPassType === BrunchPassTypes.weekend && (
            <StyledUnderlined
              layoutId="underline"
              transition={animation.default.closed}
              data-testid="underline"
            />
          )}
        </ButtonLink>
      </Alignment>

      <Alignment flexDirection="row" overflow="hidden" justifyContent="start">
        <ScrollArea
          alignItems="center"
          overflow={{ s: 'scroll', m: 'visible' }}
        >
          {getBrunchTicketTypesForPass(selectedPassType).map((subSection) => (
            <Box>
              <TicketTypeButton
                variant={
                  ticketType === subSection
                    ? variants.button.primary
                    : variants.button.secondaryGrey
                }
                onClick={() => handleClick(subSection)}
                mr={2}
                data-pw-test-id={PLAYWRIGHT_TEST_IDS.ticketFilterButton}
                key={subSection}
              >
                {getBrunchTicketTypeTitle(subSection)}
              </TicketTypeButton>
            </Box>
          ))}
        </ScrollArea>
      </Alignment>
    </div>
  )
}

const StyledUnderlined = styled(Underline)`
  position: static;
`

const ScrollArea = styled(SubNavScrollArea)`
  white-space: nowrap;
`

const TicketTypeButton = styled(Button)`
  &:focus {
    background-color: ${colors.coral};
    color: ${colors.grey['80']};
  }
`

export default BrunchTicketFilter
