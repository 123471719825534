import PropTypes from 'prop-types'
import {
  IconButton,
  FacebookIcon,
  variants,
} from '@resident-advisor/design-system'
import { FacebookShareButton } from 'react-share'

const Facebook = ({ shareUrl, iconVariant }) => (
  <FacebookShareButton url={shareUrl}>
    <IconButton as="div" variant={iconVariant} Icon={FacebookIcon} />
  </FacebookShareButton>
)

Facebook.defaultProps = {
  iconVariant: variants.iconButton.primary,
}

Facebook.propTypes = {
  iconVariant: PropTypes.string,
  shareUrl: PropTypes.string.isRequired,
}

export default Facebook
