import React, { FC } from 'react'
import NextLink from 'next/link'
import {
  Box,
  Alignment,
  Text,
  variants,
  AppStoreDownloadIcon,
  GooglePlayDownloadIcon,
  TicketIcon,
  ClockIcon,
  Icon,
} from '@resident-advisor/design-system'
import { useIntl } from 'react-intl'
import getConfig from 'next/config'
import payment from '@/messages/payment'
import dict from '@/messages/dict'
import Link from '@/components/generic/link'

const NoAppBlock: FC = () => {
  const intl = useIntl()

  return (
    <Alignment mt={30} flexDirection="column">
      <Box display={{ s: 'none', m: 'block' }}>
        <Text variant={variants.text.body}>
          {intl.formatMessage(payment.viewTickets)}{' '}
          <Link href={SUCCESS_ORDER_MODAL.RA_GUIDE_LINK} underline>
            {intl.formatMessage(dict.raGuide)}
          </Link>{' '}
          {intl.formatMessage(dict.app)}
        </Text>

        <Alignment>
          <NextLink
            href={SUCCESS_ORDER_MODAL.APP_STORE_DOWNLOAD_LINK}
            legacyBehavior
          >
            <Box p={2} style={{ cursor: 'pointer' }}>
              <AppStoreDownloadIcon width={120} height={40} />
            </Box>
          </NextLink>

          <NextLink href={SUCCESS_ORDER_MODAL.GOOGLE_PLAY_DOWNLOAD_LINK}>
            <Box p={2} style={{ cursor: 'pointer' }}>
              <GooglePlayDownloadIcon width={120} height={40} />
            </Box>
          </NextLink>
        </Alignment>
      </Box>

      <Box bg="grey.20" mt={2} py={2} px="15px" borderRadius="medium">
        <Text variant={variants.text.tag}>
          {intl.formatMessage(payment.whyDownloadRAGuide)}
        </Text>

        <Alignment mt="20px" spacing="10px">
          <Icon minWidth={24} color="coral" Component={TicketIcon} />

          <Text ml={2} variant={variants.text.small}>
            {intl.formatMessage(payment.downloadReason1)}
          </Text>
        </Alignment>

        <Alignment mt="10px">
          <Icon size={24} color="coral" Component={ClockIcon} />

          <Text ml={2} variant={variants.text.small}>
            {intl.formatMessage(payment.downloadReason2)}
          </Text>
        </Alignment>
      </Box>
    </Alignment>
  )
}

const {
  publicRuntimeConfig: { SUCCESS_ORDER_MODAL },
} = getConfig()

export default NoAppBlock
