import { useState, useEffect, MutableRefObject } from 'react'
import getConfig from 'next/config'

type BrunchTicketIframe = {
  eventId: string
  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  ref: MutableRefObject<any>
}

const IFRAME_SOURCE_BASE = 'brunch-iframe-'
const useMultiIframeMessageHandling = (
  defaultSelectedEventId: string,
  iframe1: BrunchTicketIframe,
  iframe2: BrunchTicketIframe
) => {
  const [selectedIframeId, setSelectedIframeId] = useState(
    defaultSelectedEventId
  )
  const {
    publicRuntimeConfig: { LEGACY_IFRAME_BASE_PATH },
  } = getConfig()

  useEffect(() => {
    const handleMessage = (event: MessageEvent) => {
      // Verify the origin matches expected legacy path
      if (LEGACY_IFRAME_BASE_PATH && event.origin !== LEGACY_IFRAME_BASE_PATH) {
        return
      }
      if (
        !LEGACY_IFRAME_BASE_PATH &&
        event.origin !== `https://${window.location.hostname}`
      ) {
        return
      }

      const { source, data } = event.data

      if (source === `${IFRAME_SOURCE_BASE + iframe1.eventId}`) {
        setSelectedIframeId(iframe1.eventId)
        iframe2.ref?.current?.contentWindow.postMessage(
          {
            source,
            data: {
              ...data,
              inactiveEventId: iframe2.eventId,
            },
          },
          LEGACY_IFRAME_BASE_PATH
        )
      }

      if (source === `${IFRAME_SOURCE_BASE + iframe2.eventId}`) {
        setSelectedIframeId(iframe2.eventId)
        iframe1.ref?.current?.contentWindow.postMessage(
          {
            source,
            data: {
              ...data,
              inactiveEventId: iframe1.eventId,
            },
          },
          LEGACY_IFRAME_BASE_PATH
        )
      }
    }

    window.addEventListener('message', handleMessage)
    return () => window.removeEventListener('message', handleMessage)
  }, [iframe1, iframe2, iframe1.ref, iframe2.ref, LEGACY_IFRAME_BASE_PATH])

  const onBuyTickets = () => {
    const selectedIframe =
      iframe1.eventId === selectedIframeId ? iframe1 : iframe2
    selectedIframe.ref?.current?.contentWindow.postMessage(
      {
        source: `${IFRAME_SOURCE_BASE + selectedIframe.eventId}`,
        data: {
          eventId: selectedIframe.eventId,
          action: 'buy-tickets',
        },
      },
      LEGACY_IFRAME_BASE_PATH
    )
  }

  return { onBuyTickets }
}

export default useMultiIframeMessageHandling
