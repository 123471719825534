// Duplicated version of TicketNotifications.tsx file.
// Only changes are removing default "This event is sold out" message
// and padding reduction for mobile width.

import {
  Alignment,
  Box,
  Icon,
  LetterIcon,
  Text,
  Switch,
  variants,
} from '@resident-advisor/design-system'
import PropTypes from 'prop-types'
import { useIntl } from 'react-intl'
import { useRouter } from 'next/router'
import { TRACKING_PROPERTY } from 'scripts/tracking'
import { useState } from 'react'
import messages from '@/messages/events'
import TICKET_VALID_TYPE from '@/enums/ticket-valid-type'
import UtmCampaign from '@/enums/utm-campaign'
import { useThemeContext } from '@/hooks/useThemeContext'
import LoginModal from '@/components/auth/modals/login'
import { useUserContext } from '@/context/UserContext'
import useTicketNotificationsSubscriptionData from '../../../ticket-notifications/useTicketNotificationsSubscriptionData'
import { DefaultLoadingSpinner } from '@/components/generic/query-result-handler'

const BrunchTicketNotifications: React.FC<BrunchTicketNotificationsProps> = ({
  event,
}) => {
  const intl = useIntl()
  const { isAuthenticated, user } = useUserContext()
  const [showLoginModal, setShowLoginModal] = useState(false)
  const [hasClickedSwitch, setHasClickedSwitch] = useState(false)
  const theme = useThemeContext()
  const { asPath, query } = useRouter()

  const { loading, error, isSubscribed, toggleSubscription } =
    useTicketNotificationsSubscriptionData(
      isAuthenticated,
      event?.id?.toString(),
      user?.id?.toString(),
      event?.promoters?.map((x) => x.id)
    )

  if (loading) {
    return <DefaultLoadingSpinner />
  }

  if (
    error ||
    (!event.canSubscribeToTicketNotifications &&
      !isSubscribed &&
      !hasClickedSwitch)
  ) {
    return null
  }

  const handleToggleSubscription = () => {
    if (!hasClickedSwitch) {
      setHasClickedSwitch(true)
    }

    if (!isAuthenticated) {
      setShowLoginModal(true)
    } else {
      toggleSubscription()
    }
  }

  return (
    <>
      <Box
        width={{ s: '100%', m: '680px' }} // match width of ticket tiers in iframe https://github.com/resident-advisor/ra-web/blob/a95d2c04d222f0c5d22c574c54ef2e96ec02f3d9/RA/css/next/widget.css#L637
        pb={{ s: 3, m: 4 }}
      >
        {event.standardTickets.every(
          (ticket) => ticket?.validType === TICKET_VALID_TYPE.soldOut
        ) &&
          query.utm_campaign === UtmCampaign.TicketNotification &&
          isSubscribed && (
            <Text variant={variants.text.body} as="h5">
              {intl.formatMessage(
                messages.sorryTheTicketHasAlreadyBeenPurchased
              )}
            </Text>
          )}
        <Alignment
          mt={2}
          py={{ s: 0, m: 3 }}
          px={3}
          backgroundColor="grey.10"
          flexDirection="column"
        >
          <Alignment justifyContent="space-between" alignItems="center">
            <Text variant={variants.text.bodyBold} mr={4}>
              {intl.formatMessage(
                messages.getNotifiedWhenATicketBecomesAvailable
              )}
            </Text>
            <Switch
              onChange={handleToggleSubscription}
              checked={isSubscribed}
              description={
                isSubscribed
                  ? intl.formatMessage(
                      messages.unsubscribeFromTicketNotifications
                    )
                  : intl.formatMessage(messages.subscribeToTicketNotifications)
              }
            />
          </Alignment>
          {isSubscribed && (
            <Alignment alignItems="center" pt={2}>
              <Icon
                Component={LetterIcon}
                color="accent"
                size={24}
                flexShrink={0}
                mr={3}
              />
              <Text variant={variants.text.small}>
                {intl.formatMessage(messages.ticketNotificationsConfirmation, {
                  email: user?.email,
                })}
              </Text>
            </Alignment>
          )}
        </Alignment>
      </Box>
      {showLoginModal && (
        <LoginModal
          toggleShow={() => setShowLoginModal(!showLoginModal)}
          onSuccess={toggleSubscription}
          source={TRACKING_PROPERTY.loginSource.subscribeToTicketNotifications}
          zIndex={theme.zIndex.loginModalInTicketsModal}
          returnUrl={`${asPath}#tickets`}
        />
      )}
    </>
  )
}

const brunchTicketNotificationsPropTypes = {
  event: PropTypes.shape({
    id: PropTypes.string.isRequired,
    canSubscribeToTicketNotifications: PropTypes.bool.isRequired,
    standardTickets: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        validType: PropTypes.oneOf(Object.values(TICKET_VALID_TYPE)).isRequired,
      })
    ),
    promoters: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      })
    ),
  }).isRequired,
}

BrunchTicketNotifications.propTypes = brunchTicketNotificationsPropTypes

type BrunchTicketNotificationsProps = PropTypes.InferProps<
  typeof brunchTicketNotificationsPropTypes
>

export type { BrunchTicketNotificationsProps }
export default BrunchTicketNotifications
