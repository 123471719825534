import { useQuery } from '@apollo/client'
import { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import dynamic from 'next/dynamic'
import get from 'lodash/get'
import { format, parseISO } from 'date-fns'
import { ThemeProvider } from 'styled-components'
import getEventImage from 'lib/getEventImage'
import {
  Section,
  Grid,
  Column,
  SubSection,
} from '@resident-advisor/design-system'
import arrayHasData from 'lib/arrayHasData'
import Tracking from 'scripts/tracking'
import TRACKING_EVENT from 'scripts/tracking/tracking-event'
import useEventDetailMixpanelProperties from 'scripts/tracking/hooks/useEventDetailMixpanelProperties'
import { dark } from '@/themes'
import IMAGE_TYPE from '@/enums/image-type'
import ENTITY_TYPE from '@/enums/entity-type'
import featureSwitches from '@/enums/feature-switches'
import AD_TARGETING_KEYS from '@/enums/ad-targeting-keys'
import trackingIds from '@/tracking/tracking-ids'
import testIds from '@/enums/testIds'
import { useUserContext } from '@/context/UserContext'
import Padded from '@/components/generic/padded'
import { EventSocialMediaBar } from '@/components/generic/social-media-bar'
import QueryResultHandler from '@/components/generic/query-result-handler'
import { EventJsonLd } from '@/components/generic/json-ld'
import Throw404 from '@/components/generic/throw-404'
import Throw500 from '@/components/generic/throw-500'
import EventDescription from '@/components/events/pages/event-detail/event-description'
import useThirdPartyAffiliateCookie from '@/hooks/useThirdPartyAffiliateCookie'
import usePageviewTracking, { PageTypes } from '@/hooks/usePageviewTracking'
import { useAdTargetingDispatch } from '@/hooks/useAdTargeting'
import {
  useFeatureSwitchesContext,
  useFeatureSwitch,
} from '@/context/FeatureSwitchesContext'
import useShouldShowEventTickets from '@/hooks/useShouldShowEventTickets/useShouldShowEventTickets'
import PresaleStatus from '@/enums/presale-status'
import ConfirmOrderModal from '@/components/events/pages/event-detail/confirm-order'
import TICKETING_SYSTEM from '@/enums/ticketing-system'
import {
  TicketingTicketsPurchased,
  TicketsPurchased,
} from '@/components/events/pages/event-detail/tickets-purchased/TicketsPurchased'
import {
  usePerformanceTracking,
  PerformanceTrackingTypes,
} from '@/context/PerformanceContext'
import useIsMobile from '@/hooks/useIsMobile'
import Redirect from '@/components/generic/redirect/Redirect'
import GET_EVENT_DETAIL from './GetEventDetailQuery'
import Lineup from './lineup'
import Genres from './genres'
import EventTickets, { useTicketsPromoCode } from './event-tickets'
import extractEventTrackingCodes from './extractEventTrackingCodes'
import EventSEO from './event-seo'
import EventHeader from './event-header'
import PresaleSignUp from './presale-signup/PresaleSignUp'
import BrunchEventTickets from './event-tickets/brunch-event-tickets/BrunchEventTickets'
import useBrunchRedirectUrl from './event-tickets/brunch-event-tickets/helpers/useBrunchRedirectUrl'
import useEnableNewBrunchTicketing from './event-tickets/brunch-event-tickets/useEnableNewBrunchTicketing'

const EventDetailRelatedContent = dynamic(
  () =>
    import('./EventDetailRelatedContent').then(
      (module) => module.EventDetailRelatedContent
    ),
  {
    ssr: false,
  }
)

const EventDetail = ({ id, ticketType }) => {
  const enablePresalePage = useFeatureSwitch(featureSwitches.enablePresalePage)
  const enableNewBrunchTicketing = useEnableNewBrunchTicketing()

  const { isAuthenticated } = useUserContext()

  const canAccessPresale = isAuthenticated && enablePresalePage

  const queryResult = useQuery(GET_EVENT_DETAIL, {
    variables: {
      id,
      isAuthenticated,
      canAccessPresale,
      enableNewBrunchTicketing,
    },
  })

  const brunchRedirectUrl = useBrunchRedirectUrl(id)

  if (brunchRedirectUrl) {
    return <Redirect to={brunchRedirectUrl} ssr />
  }

  return (
    <>
      <QueryResultHandler
        ticketType={ticketType}
        enableNewBrunchTicketing={enableNewBrunchTicketing}
        {...queryResult}
        dataKey="event"
        markup={EventDetailMarkup}
        handleError={() => <Throw500 />}
        handleEmpty={() => <Throw404 entityType={ENTITY_TYPE.Event} />}
      />
    </>
  )
}

EventDetail.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  ticketType: PropTypes.string,
}

const EventDetailMarkup = ({ data, ticketType, enableNewBrunchTicketing }) => {
  const isSignedUpToPresale = data?.isSignedUpToPresale
  const isActivePresale = data?.presaleStatus === PresaleStatus.Active
  const [hasTrackedViewEventDetail, setHasTrackedViewEventDetail] =
    useState(false)

  usePageviewTracking(PageTypes.Event, data.id)
  useThirdPartyAffiliateCookie(data.id)

  const ticketsPromoCode = useTicketsPromoCode({
    ticketingSystem: data.ticketingSystem,
  })

  const { updateContext } = useFeatureSwitchesContext()

  useEffect(() => {
    if (data.promoters?.[0]?.id) {
      updateContext({
        properties: {
          eventPromoterId: data.promoters[0].id,
          eventPromoterIds: JSON.stringify(
            data.promoters.map((promoter) => promoter.id)
          ),
        },
      })
    }
  }, [data.promoters, updateContext])

  const shouldShowEventTickets = useShouldShowEventTickets(
    data.userOrders,
    data.childEvents,
    data.isTicketed,
    data.date,
    data.tickets,
    data.ticketingSystem
  )

  const isMobile = useIsMobile()

  usePerformanceTracking(
    shouldShowEventTickets
      ? PerformanceTrackingTypes.LoadEventDetailsTicketed
      : PerformanceTrackingTypes.LoadEventDetailsNonTicketed,
    shouldShowEventTickets && !isMobile
  )

  const { data: mixpanelProperties, loading } =
    useEventDetailMixpanelProperties(
      data.id,
      ticketsPromoCode,
      shouldShowEventTickets
    )

  Tracking.useTracking(
    {
      gtm: extractEventTrackingCodes(data),
      mixpanel: {
        event: TRACKING_EVENT.viewEventDetail,
        properties: mixpanelProperties,
        skip: loading,
      },
    },
    () => setHasTrackedViewEventDetail(true)
  )

  Tracking.useTrackingExperiment(
    featureSwitches.promoterTicketFeeTypeExperiment,
    /**
     * Skip starting this experiment until we've tracked viewing the event.
     * This is so we get reliable results when tracking the flow of
     * viewing an event -> starting this experiment -> buying a ticket.
     */
    { skip: !hasTrackedViewEventDetail }
  )

  useAdTargetingDispatch(
    buildAdTargeting(
      data.contentUrl,
      data.venue,
      data.artists,
      data.promoters,
      data.isFestival,
      data.genres
    )
  )

  let fallbackDate

  if (!data.startTime || !data.endTime) {
    fallbackDate = format(parseISO(data.date), 'yyyy-MM-dd')
  }

  const flyerFrontUrl = getEventImage(data, IMAGE_TYPE.flyerFront)

  const isNewAPITickets = data?.ticketingSystem === TICKETING_SYSTEM.entire

  return (
    <ThemeProvider theme={dark}>
      <EventSEO data={data} />
      <EventJsonLd
        title={data.title}
        startDate={data.startTime || fallbackDate}
        endDate={data.endTime || fallbackDate}
        description={get(data, 'pick.blurb', data.content)}
        images={[flyerFrontUrl]}
        artists={data.artists || []}
        venueName={get(data.venue, 'name')}
        venueAddress={get(data.venue, 'address')}
        venueRegion={get(data.venue, 'area.name')}
        venueCountry={get(data.venue, 'area.country.isoCode')}
        venueCountryUrlCode={get(data.venue, 'area.country.urlCode')}
        venueLocation={get(data.venue, 'location')}
        promoters={data.promoters}
        contentUrl={data.contentUrl}
        tickets={data.tickets}
      />
      {isNewAPITickets && <ConfirmOrderModal />}
      <EventHeader
        data={data}
        shouldShowEventTickets={shouldShowEventTickets}
        enableNewBrunchTicketing={enableNewBrunchTicketing}
      >
        {isActivePresale && !ticketsPromoCode ? (
          <PresaleSignUp
            eventId={data.id}
            isSignedUpToPresale={isSignedUpToPresale}
          />
        ) : (
          shouldShowEventTickets &&
          (enableNewBrunchTicketing ? (
            <BrunchEventTickets
              ticketType={ticketType}
              brunchEvents={data.childEvents}
            />
          ) : (
            <EventTickets
              event={data}
              shouldShowEventTickets={shouldShowEventTickets}
            />
          ))
        )}
      </EventHeader>
      <Section
        data-tracking-id={trackingIds.eventDetail}
        data-test-id={testIds.eventDetail}
      >
        {isNewAPITickets ? (
          <TicketingTicketsPurchased eventId={data.id} />
        ) : (
          <TicketsPurchased userOrders={data.userOrders} />
        )}
        <Padded>
          <SubSection>
            <Grid lCols={4} mCols={4} sCols={1} sWrap>
              {data.lineup && (
                <Column lSpan={3} mSpan={3}>
                  <Lineup
                    lineup={data.lineup}
                    artists={data.artists}
                    setTimes={data.setTimes}
                  />
                </Column>
              )}
              <Column>
                <EventSocialMediaBar contentUrl={data.contentUrl} />
              </Column>
            </Grid>
          </SubSection>
          {arrayHasData(data.genres) && (
            <SubSection>
              <Grid lCols={4} mCols={4} sCols={1} sWrap>
                <Column lSpan={3} mSpan={3}>
                  <Genres genres={data.genres} area={data.venue.area} />
                </Column>
              </Grid>
            </SubSection>
          )}
          <EventDescription {...data} />
        </Padded>
      </Section>
      <EventDetailRelatedContent data={data} />
    </ThemeProvider>
  )
}

EventDetailMarkup.propTypes = {
  seo: PropTypes.object,
  data: PropTypes.object.isRequired,
  ticketType: PropTypes.string,
  enableNewBrunchTicketing: PropTypes.bool,
}

const buildAdTargeting = (
  path,
  venue,
  artists,
  promoters,
  isFestival,
  genres
) => {
  const adTargeting = [
    [AD_TARGETING_KEYS.PAGE, path],
    [AD_TARGETING_KEYS.CATEGORY, 'Listings'],
    [AD_TARGETING_KEYS.SECTION, 'Events'],
    [AD_TARGETING_KEYS.CLUB, venue.id.toString()],
    [AD_TARGETING_KEYS.COUNTRY, venue.area.country.name],
    [AD_TARGETING_KEYS.AREA, venue.area.name],
    [AD_TARGETING_KEYS.EVENT_TYPE, isFestival ? 'Festival' : 'Standard'],
  ]

  if (arrayHasData(artists)) {
    adTargeting.push([
      AD_TARGETING_KEYS.ARTIST,
      artists.map((artist) => artist.id.toString()),
    ])
  }

  if (arrayHasData(promoters)) {
    adTargeting.push([
      AD_TARGETING_KEYS.PROMOTER,
      promoters.map((promoter) => promoter.id.toString()),
    ])
  }

  if (arrayHasData(genres)) {
    adTargeting.push([
      AD_TARGETING_KEYS.GENRES,
      genres.map((genre) => genre.slug),
    ])
  }

  return adTargeting
}

export { EventDetailMarkup }
export default EventDetail
