import PropTypes from 'prop-types'
import SoundcloudPlaylist from '@/components/shared/audio-embed/soundcloud-playlist'
import YoutubeVideo from '@/components/shared/audio-embed/youtube-video'
import SpotifyAlbum from '@/components/shared/audio-embed/spotify-album'
import SoundcloudTrack from '@/components/shared/audio-embed/soundcloud-track'
import BandcampPlayer from '@/components/shared/audio-embed/bandcamp-player'
import MediaPlayer from '@/components/shared/audio-embed/media-player'
import audioServices from '@/enums/audio-services'

const PlayerWidget = ({ player, title }) => {
  switch (parseInt(player?.audioService?.id)) {
    case audioServices.BANDCAMP_TRACK: {
      return (
        <BandcampPlayer sourceId={player.sourceId} title={title} type="track" />
      )
    }

    case audioServices.BANDCAMP_ALBUM: {
      return (
        <BandcampPlayer sourceId={player.sourceId} title={title} type="album" />
      )
    }

    case audioServices.SOUNDCLOUD_TRACK: {
      return <SoundcloudTrack sourceId={player.sourceId} title={title} />
    }

    case audioServices.SOUNDCLOUD_PLAYLIST: {
      return <SoundcloudPlaylist sourceId={player.sourceId} title={title} />
    }

    case audioServices.SPOTIFY_ALBUM: {
      return <SpotifyAlbum sourceId={player.sourceId} title={title} />
    }

    case audioServices.YOUTUBE: {
      return <YoutubeVideo sourceId={player.sourceId} title={title} />
    }

    case audioServices.FULL_MEDIA_URL: {
      return <MediaPlayer url={player.sourceId} />
    }

    default:
      return null
  }
}

PlayerWidget.propTypes = {
  player: PropTypes.shape({
    id: PropTypes.oneOfType([
      PropTypes.string.isRequired,
      PropTypes.number.isRequired,
    ]),
    sourceId: PropTypes.string.isRequired,
    audioService: PropTypes.shape({
      id: PropTypes.oneOfType([
        PropTypes.string.isRequired,
        PropTypes.number.isRequired,
      ]),
      name: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  title: PropTypes.string.isRequired,
}

export default PlayerWidget
