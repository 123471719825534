import PropTypes from 'prop-types'
import { useIntl } from 'react-intl'
import { variants, Detail, Tag } from '@resident-advisor/design-system'
import styled, { css } from 'styled-components'
import eventUrlFormatters from 'lib/eventUrlFormatters'
import { eventsByAreaRoute } from 'lib/routes'
import Link from '@/components/generic/link'
import messages from '@/messages/events'

const Genres = ({ genres, area }) => {
  const intl = useIntl()

  const { formatGenreUrl } = eventUrlFormatters({
    area,
    basePath: '/events',
    dynamicRouteFormatter: eventsByAreaRoute,
  })

  return (
    <>
      <Detail
        color="grey.30"
        title={intl.formatMessage(messages.genre, {
          genreCount: genres.length,
        })}
      />
      {genres.map((genre, index) => (
        <LinkWrapper key={index + genre.id}>
          <Link {...formatGenreUrl(genre.slug)}>
            <Tag variant={variants.tag.outline} inline>
              {genre.name}
            </Tag>
          </Link>
        </LinkWrapper>
      ))}
    </>
  )
}

Genres.propTypes = {
  genres: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      slug: PropTypes.string.isRequired,
    })
  ).isRequired,
  area: PropTypes.shape({
    urlName: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    country: PropTypes.shape({
      urlCode: PropTypes.string.isRequired,
    }),
  }).isRequired,
}

const LinkWrapper = styled.div`
  ${({ theme }) =>
    css`
      display: inline-block;
      margin-right: 10px;

      &:hover {
        background-color: ${theme.colors.accent};
      }
    `};
`

export default Genres
