import PropTypes from 'prop-types'
import PlayerWidgetContainer from '@/components/shared/audio-embed/player-widget-container'

const SpotifyAlbum = ({ sourceId, title }) => (
  <PlayerWidgetContainer
    aspectRatio="66.67%"
    title={title}
    frameBorder="0"
    allowtransparency="true"
    allow="encrypted-media"
    src={`https://open.spotify.com/embed/album/${sourceId}`}
  />
)

SpotifyAlbum.propTypes = {
  sourceId: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
}

export default SpotifyAlbum
