const THIRD_PARTY_AFFILIATE = Object.freeze({
  spotify: 'spotify',
  soundcloud: 'soundcloud',
})

type ThirdPartyAffiliate =
  typeof THIRD_PARTY_AFFILIATE[keyof typeof THIRD_PARTY_AFFILIATE]

export type { ThirdPartyAffiliate }
export default THIRD_PARTY_AFFILIATE
