import {
  SubSection,
  Box,
  variants,
  Text,
  Alignment,
  colors,
  LetterIcon,
  Icon,
  Button,
} from '@resident-advisor/design-system'
import { useIntl } from 'react-intl'
import { useState } from 'react'
import messages from '@/messages/events'
import { padding } from '@/themes'
import pro from '@/messages/pro'
import { useUserContext } from '@/context/UserContext'
import LoginModal from '@/components/auth/modals/login'
import usePresaleSignUp from './usePresaleSignUp'

const PresaleSignUp = ({
  eventId,
  isSignedUpToPresale,
}: PresaleSignUpProps) => {
  const intl = useIntl()
  const [showLoginModal, setShowLoginModal] = useState(false)
  const { isAuthenticated, user } = useUserContext()
  const userEmail = user?.email

  const { handleSignUpPresale } = usePresaleSignUp(eventId)

  const handleButtonClick = () => {
    if (isAuthenticated) {
      handleSignUpPresale()
    } else {
      setShowLoginModal(true)
    }
  }

  return (
    <>
      <SubSection
        title={intl.formatMessage(messages.raTickets)}
        py={padding.pt}
        variant={variants.section.translucent}
      >
        {isSignedUpToPresale ? (
          <Box backgroundColor={colors.grey[10]} p={3}>
            <Text variant={variants.text.bodyBold} mr={4}>
              {intl.formatMessage(pro.signedUpForPresale)}
            </Text>
            <Alignment alignItems="center" pt={2}>
              <Icon
                Component={LetterIcon}
                color="accent"
                size={24}
                flexShrink={0}
                mr={3}
              />
              <Text variant={variants.text.small}>
                {intl.formatMessage(pro.presaleScheduledEmail, {
                  email: userEmail,
                })}
              </Text>
            </Alignment>
          </Box>
        ) : (
          <Box backgroundColor={colors.grey[10]} p={3}>
            <Alignment justifyContent="space-between" display="flex">
              <Text variant={variants.text.bodyBold} pt={2}>
                {intl.formatMessage(pro.presaleEarlyAccess)}
              </Text>
              <Button
                onClick={handleButtonClick}
                variant={variants.button.primary}
              >
                {intl.formatMessage(pro.signUpForPresale)}
              </Button>
            </Alignment>
          </Box>
        )}
      </SubSection>
      {showLoginModal && (
        <LoginModal
          toggleShow={() => setShowLoginModal((value) => !value)}
          onSuccess={() => {
            handleSignUpPresale()
            setShowLoginModal(false)
          }}
        />
      )}
    </>
  )
}

interface PresaleSignUpProps {
  eventId: string
  isSignedUpToPresale: boolean
}

export default PresaleSignUp
