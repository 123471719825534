import {
  Detail,
  Alignment,
  variants,
  Box,
} from '@resident-advisor/design-system'
import PropTypes from 'prop-types'
import { useIntl } from 'react-intl'
import getConfig from 'next/config'
import dynamic from 'next/dynamic'
import messages from '@/messages/dict'

import { Facebook, Twitter, Whatsapp } from './icons'

const { publicRuntimeConfig } = getConfig()

const WebShare = dynamic(() => import('./icons/WebShare'), {
  ssr: false,
})

const EditorialSocialMediaBar = ({ contentUrl }) => {
  const intl = useIntl()
  const shareUrl = `${publicRuntimeConfig.NEXT_WEB_ADDRESS}${contentUrl}`

  return (
    <Detail title={intl.formatMessage(messages.share)}>
      <Alignment flexDirection={{ s: 'row', m: 'column' }}>
        <Box mb={2} mr={2}>
          <Facebook
            iconVariant={variants.iconButton.secondary}
            shareUrl={shareUrl}
          />
        </Box>
        <Box mb={2} mr={2}>
          <Twitter
            iconVariant={variants.iconButton.secondary}
            shareUrl={shareUrl}
          />
        </Box>
        <Box mb={2} mr={2}>
          <Whatsapp
            iconVariant={variants.iconButton.secondary}
            shareUrl={shareUrl}
          />
        </Box>
        <WebShare
          iconVariant={variants.iconButton.secondary}
          shareUrl={shareUrl}
        />
      </Alignment>
    </Detail>
  )
}

EditorialSocialMediaBar.propTypes = {
  contentUrl: PropTypes.string.isRequired,
}

EditorialSocialMediaBar.displayName = 'EditorialSocialMediaBar'

const EventSocialMediaBar = ({ contentUrl }) => {
  const intl = useIntl()
  const shareUrl = `${publicRuntimeConfig.NEXT_WEB_ADDRESS}${contentUrl}`

  const variant = variants.iconButton.secondary

  return (
    <Detail
      title={intl.formatMessage(messages.share)}
      flexDirection={{ s: 'column', m: 'row' }}
      alignItems={{ s: 'flex-start', m: 'center' }}
    >
      <Alignment flexDirection={{ s: 'row' }} ml={{ s: 0, m: 2 }}>
        <Box mr={2}>
          <Facebook shareUrl={shareUrl} iconVariant={variant} />
        </Box>
        <Box mr={2}>
          <Twitter shareUrl={shareUrl} iconVariant={variant} />
        </Box>
        <Box mr={2}>
          <Whatsapp shareUrl={shareUrl} iconVariant={variant} />
        </Box>
        <WebShare shareUrl={shareUrl} iconVariant={variant} />
      </Alignment>
    </Detail>
  )
}

EventSocialMediaBar.propTypes = {
  contentUrl: PropTypes.string.isRequired,
}

EventSocialMediaBar.displayName = 'EventSocialMediaBar'

export { EditorialSocialMediaBar, EventSocialMediaBar }
