import PropTypes from 'prop-types'
import { withTheme } from 'styled-components'
import PlayerWidgetContainer from '@/components/shared/audio-embed/player-widget-container'

const SoundcloudTrack = ({
  theme,
  sourceId,
  title,
  color = theme.soundcloud.defaultColor,
}) => {
  const encodedColor = encodeURIComponent(color)

  return (
    <PlayerWidgetContainer
      aspectRatio="30%"
      title={title}
      src={`https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/${sourceId}&color=${encodedColor}&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true`}
    />
  )
}

SoundcloudTrack.propTypes = {
  sourceId: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  color: PropTypes.string,
  theme: PropTypes.shape({
    soundcloud: PropTypes.shape({
      defaultColor: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
}

SoundcloudTrack.displayName = 'SoundcloudTrack'

export default withTheme(SoundcloudTrack)
