import PropTypes from 'prop-types'
import { Detail, variants, Box } from '@resident-advisor/design-system'
import { useIntl } from 'react-intl'
import { isAfter } from 'date-fns'
import BEACON_ACTION from '@/enums/beacon-action'

import messages from '@/messages/events'
import featureSwitches from '@/enums/feature-switches'
import Link from '@/components/generic/link'
import ButtonLink from '@/components/generic/button-link'

import { useFeatureSwitch } from '@/context/FeatureSwitchesContext'
import {
  useBeaconContext,
  useHiddenBeaconButton,
  usePrefillBeacon,
  PREFILL_FIELD_IDS,
} from '@/components/generic/helpscout'

const EventContactDetail = ({ id, startTime, admin, title }) => {
  useHiddenBeaconButton()

  const contactPromoter = isAfter(new Date(startTime), new Date()) && admin

  return contactPromoter ? (
    <ContactPromoter username={admin.username} title={title} />
  ) : (
    <ContactRATicketSupport eventId={id} />
  )
}

const ContactRATicketSupport = ({ eventId }) => {
  const intl = useIntl()
  const { callBeacon } = useBeaconContext()

  usePrefillBeacon([
    {
      id: PREFILL_FIELD_IDS.EventID,
      value: eventId,
    },
  ])

  const onClick = () => callBeacon(BEACON_ACTION.toggle)

  const contactMessage = intl.formatMessage(messages.problemWithTheEvent)
  const contactLinkMessage = intl.formatMessage(messages.contactRATicketSupport)

  return (
    <Detail title={contactMessage}>
      <Box>
        <ButtonLink onClick={onClick} variant={variants.text.small} underline>
          {contactLinkMessage}
        </ButtonLink>
      </Box>
    </Detail>
  )
}

const ContactPromoter = ({ username, title }) => {
  const intl = useIntl()
  const enableNewMainNav = useFeatureSwitch(featureSwitches.enableNewMainNav)

  const contactMessage = intl.formatMessage(messages.questionAboutTheEvent)
  const contactLinkMessage = intl.formatMessage(messages.contactThePromoter)
  const inboxComposePath = enableNewMainNav
    ? '/user/inbox-compose'
    : '/shop/inbox-compose.aspx'
  const contactUrl = `${inboxComposePath}?sendto=${username}&subject=${title}`

  return (
    <Detail title={contactMessage}>
      <Link
        href={contactUrl}
        key={contactUrl}
        underline
        variant={variants.text.small}
      >
        {contactLinkMessage}
      </Link>
    </Detail>
  )
}

ContactRATicketSupport.propTypes = {
  eventId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
}

ContactPromoter.propTypes = {
  username: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
}

EventContactDetail.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  startTime: PropTypes.string.isRequired,
  admin: PropTypes.object,
  title: PropTypes.string.isRequired,
}

export default EventContactDetail
