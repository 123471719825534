import axios from 'axios'
import getConfig from 'next/config'
import { useEffect, useState } from 'react'
import { captureError } from 'lib/logger'
import audioServices from '@/enums/audio-services'

const { publicRuntimeConfig } = getConfig()
const soundcloudClientId = publicRuntimeConfig.SOUNDCLOUD_CLIENT_ID

const useBuildAudioPlayerUrls = (players) => {
  const [loading, setLoading] = useState(true)
  const [externalUrls, setExternalUrls] = useState({})

  useEffect(() => {
    const buildAudioPlayerUrls = async () => {
      setLoading(true)
      let result = {}

      await players.forEach(async (player) => {
        const externalUrl = await playerUrlLookup[player.audioService.id](
          player
        )

        if (externalUrl) {
          result = {
            ...result,
            [player.audioService.id]: externalUrl,
          }
        }
      })

      setExternalUrls(result)
      setLoading(false)
    }

    if (players?.length) {
      buildAudioPlayerUrls()
    }
  }, [players])

  return { externalUrls, loading }
}

const playerUrlLookup = {
  [audioServices.SOUNDCLOUD_TRACK]: async (player) => {
    const apiUrl = `https://api.soundcloud.com/tracks/${player.sourceId}.json?client_id=${soundcloudClientId}`
    const result = await getUrlFromApi(apiUrl, player)

    return result?.data?.permalink_url
  },
  [audioServices.SOUNDCLOUD_PLAYLIST]: async (player) => {
    const apiUrl = `https://api.soundcloud.com/playlists/${player.sourceId}.json?client_id=${soundcloudClientId}`
    const result = await getUrlFromApi(apiUrl, player)

    return result?.data?.permalink_url
  },
  [audioServices.SPOTIFY_ALBUM]: (player) =>
    `https://open.spotify.com/album/${player.sourceId}`,
  [audioServices.YOUTUBE]: (player) =>
    `https://www.youtube.com/watch?v=${player.sourceId}`,
  [audioServices.APPLE_MUSIC]: (player) =>
    `https://music.apple.com/album/${player.sourceId}?mt=1&ls=1`,
  [audioServices.BANDCAMP_TRACK]: () => 'https://bandcamp.com',
  [audioServices.BANDCAMP_ALBUM]: () => 'https://bandcamp.com',
}

const getUrlFromApi = async (url, player) => {
  try {
    const result = await axios(url)
    return result
  } catch (e) {
    const error = new Error()
    error.name = 'AudioPlayerUrlError'
    error.message = `${e.message}: ${JSON.stringify({
      audioServiceId: player.audioService.id,
      sourceId: player.sourceId,
    })}`
    captureError(error)
    return null
  }
}

export default useBuildAudioPlayerUrls
