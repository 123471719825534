import getConfig from 'next/config'
import { ParsedUrlQuery } from 'querystring'
import { useRouter } from 'next/router'
import featureSwitches from '@/enums/feature-switches'
import { useFeatureSwitch } from '@/context/FeatureSwitchesContext'
import { getBrunchTicketTypeForEventId } from './BrunchChildEventMappings'
import { BrunchTicketType } from './BrunchTicketTypes'

const {
  publicRuntimeConfig: {
    BRUNCH_CONFIG: { BRUNCH_PARENT_EVENT_ID },
  },
} = getConfig()

const useBrunchRedirectUrl = (eventId: string) => {
  const enableNewBrunchTicketing = useFeatureSwitch(
    featureSwitches.enableNewBrunchTicketing
  )
  const { query, asPath } = useRouter()

  if (!enableNewBrunchTicketing) {
    return null
  }

  const brunchTicketType = getBrunchTicketTypeForEventId(eventId)

  if (brunchTicketType === undefined) {
    return null
  }

  return buildRedirectUrl(brunchTicketType, query, asPath)
}

const buildRedirectUrl = (
  brunchTicketType: BrunchTicketType,
  query: BrunchQueryParams,
  asPath: string
): string => {
  const baseUrl = `/events/${BRUNCH_PARENT_EVENT_ID}?ticketType=${brunchTicketType}`
  const hash = asPath.split('#')[1]

  // extracting idOrCountry from the query
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { idOrCountry, ...restOfQuery } = query

  const params = Object.keys({ ...restOfQuery })
    .map((key) => `${key}=${query[key]}`)
    .join('&')

  const redirectUrl = [baseUrl, params && `&${params}`, hash && `#${hash}`]
    .filter(Boolean)
    .join('')

  return redirectUrl
}

interface BrunchQueryParams extends ParsedUrlQuery {
  p?: string
  promo?: string
}

export default useBrunchRedirectUrl
