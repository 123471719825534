import canUseDom from './canUseDom'

const detectPlatform = () => {
  if (!canUseDom())
    return {
      isIOS: false,
      isAndroid: false,
      isUnknown: true,
    }

  const { userAgent } = window.navigator

  const isIOS = !!userAgent.match(/iPad|iPhone|iPod/)
  const isAndroid = !!userAgent.match(/Android/)

  return {
    isIOS,
    isAndroid,
    isUnknown: !isIOS && !isAndroid,
  }
}

export default detectPlatform
