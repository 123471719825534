import { gql } from '@apollo/client'

const GET_TICKETING_ORDER_DETAIL_QUERY = gql`
  query GET_TICKETING_ORDER_DETAIL($orderNumber: String!) {
    Ticketing_me {
      id
      order(where: { orderNumber: $orderNumber }) {
        id
        displayAmount
        displayFee
        paymentMethod
        createdAt
        refundStatus
        refundAmounts {
          refundedFeeAmount
          refundedRetailAmount
          refundedWholesaleAmount
        }
        orderItems {
          id
          displayAmount
          displayFee
          refundAmounts {
            refundedRetailAmount
            refundedFeeAmount
          }
          refundStatus
          allBarcodes {
            id
            valid
          }
          promo {
            id
            code
            type
          }
          ticketTier {
            id
            title
            currency {
              id
              code
            }
            event {
              id
              legacy {
                id
                title
                endTime
                startTime
                venue {
                  id
                  name
                }
              }
            }
          }
        }
      }
    }
  }
`

export default GET_TICKETING_ORDER_DETAIL_QUERY
