import PropTypes from 'prop-types'
import { Text, Icon, variants } from '@resident-advisor/design-system'
import LinkButton from '@/components/generic/link-button'

const PlayerLink = ({ children, Icon: IconProp, href }) => (
  <LinkButton
    mb={2}
    variant={variants.iconButton.secondary}
    href={href}
    target="_blank"
    rel="noopener"
  >
    <Icon Component={IconProp} />
    <Text pl={1} variant={variants.text.label}>
      {children}
    </Text>
  </LinkButton>
)

PlayerLink.propTypes = {
  children: PropTypes.node.isRequired,
  Icon: PropTypes.elementType.isRequired,
  href: PropTypes.string.isRequired,
}

export default PlayerLink
