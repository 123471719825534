import PropTypes from 'prop-types'
import { Alignment, Box, SlashHeader } from '@resident-advisor/design-system'
import { useIntl } from 'react-intl'
import { PlayerWidget } from '@/components/shared/audio-embed'
import dict from '@/messages/dict'

const EventMediaLinks = ({ playerLinks }) => {
  const intl = useIntl()

  return (
    <Alignment flexDirection="column">
      <SlashHeader>{intl.formatMessage(dict.media)}</SlashHeader>
      {playerLinks.map((playerLink) => (
        <Box mb={4} key={playerLink.id}>
          <PlayerWidget
            title={playerLink.audioService?.name}
            player={playerLink}
          />
        </Box>
      ))}
    </Alignment>
  )
}

EventMediaLinks.propTypes = {
  playerLinks: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      sourceId: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
        .isRequired,
      audioService: PropTypes.shape({
        id: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
          .isRequired,
        name: PropTypes.string.isRequired,
      }).isRequired,
    })
  ).isRequired,
}

export default EventMediaLinks
