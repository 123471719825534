export enum BrunchTicketType {
  TWO_DAY = '2day',
  THREE_DAY = '3day',
  THURSDAY = 'thursday',
  FRIDAY = 'friday',
  SATURDAY = 'saturday',
  SUNDAY = 'sunday',
}

export enum BrunchVIPTicketType {
  TWO_DAY_VIP = '2day-vip',
  THREE_DAY_VIP = '3day-vip',
  THURSDAY_VIP = 'thursday-vip',
  FRIDAY_VIP = 'friday-vip',
  SATURDAY_VIP = 'saturday-vip',
  SUNDAY_VIP = 'sunday-vip',
}

export type SelectedTicketType = {
  baseTicketType: BrunchTicketType
  vipTicketType: BrunchVIPTicketType
}

export const stringToBrunchTicketType = (
  s: string
): SelectedTicketType | null => {
  switch (s) {
    case '2day':
      return {
        baseTicketType: BrunchTicketType.TWO_DAY,
        vipTicketType: BrunchVIPTicketType.TWO_DAY_VIP,
      }
    case '3day':
      return {
        baseTicketType: BrunchTicketType.THREE_DAY,
        vipTicketType: BrunchVIPTicketType.THREE_DAY_VIP,
      }
    case 'thursday':
      return {
        baseTicketType: BrunchTicketType.THURSDAY,
        vipTicketType: BrunchVIPTicketType.THURSDAY_VIP,
      }
    case 'friday':
      return {
        baseTicketType: BrunchTicketType.FRIDAY,
        vipTicketType: BrunchVIPTicketType.FRIDAY_VIP,
      }
    case 'saturday':
      return {
        baseTicketType: BrunchTicketType.SATURDAY,
        vipTicketType: BrunchVIPTicketType.SATURDAY_VIP,
      }
    case 'sunday':
      return {
        baseTicketType: BrunchTicketType.SUNDAY,
        vipTicketType: BrunchVIPTicketType.SUNDAY_VIP,
      }
    default:
      return null
  }
}
