import arrayHasData from 'lib/arrayHasData'
import TICKET_VALID_TYPE, { TicketValidType } from '@/enums/ticket-valid-type'

type StandardTicket = {
  id: string
  validType: TicketValidType
}

export type BrunchEvent = {
  id: string
  canSubscribeToTicketNotifications: boolean
  promoters: {
    id: string
  }[]
  standardTickets: StandardTicket[]
  promoCode: string
  isSoldOut?: boolean
  isVip?: boolean
}

export const brunchEventIsSoldOut = (
  standardTickets: StandardTicket[]
): boolean => {
  if (!arrayHasData(standardTickets)) {
    return false
  }
  return standardTickets.every(
    (ticket) => ticket.validType === TICKET_VALID_TYPE.soldOut
  )
}
