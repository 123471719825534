import BrunchTicketNotifications from '../brunch-ticket-notifications'
import BrunchTicketsIframe from '../brunch-tickets-iframe/BrunchTicketsIframe'
import {
  BrunchTicketType,
  BrunchVIPTicketType,
} from '../helpers/BrunchTicketTypes'
import BrunchTicketTypeTitle from '../brunch-ticket-type-title/BrunchTicketTypeTitle'
import { BrunchEvent } from '../helpers/BrunchEvent'

type BrunchTicketSectionProps = {
  event: BrunchEvent
  ticketType: BrunchTicketType | BrunchVIPTicketType
  defaultSelectedEventId: string
  id: string
  onLoad: (x) => void
}

const BrunchTicketSection = ({
  event,
  ticketType,
  defaultSelectedEventId,
  id,
  onLoad,
}: BrunchTicketSectionProps) => {
  return (
    <>
      <BrunchTicketTypeTitle
        ticketType={ticketType}
        isSoldOut={event.isSoldOut}
        isVip={event.isVip}
      />
      <BrunchTicketNotifications event={event} />
      <BrunchTicketsIframe
        event={event}
        defaultSelectedEventId={defaultSelectedEventId}
        id={id}
        onLoad={(x) => onLoad(x)}
      />
    </>
  )
}

export default BrunchTicketSection
