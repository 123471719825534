const extractEventTrackingCodes = (data) => {
  const promoterCodes = data.promoters.reduce(
    (acc, promoter) => [...acc, ...promoter.tracking],
    []
  )

  return [...data.tracking, ...promoterCodes]
}

export default extractEventTrackingCodes
