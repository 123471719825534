import { getAreaSafeName } from 'lib/utils'
import arrayHasData from 'lib/arrayHasData'
import isMultiDayEvent from 'lib/isMultiDayEvent'
import formatDate from 'date-fns/format'
import getYear from 'date-fns/getYear'
import { toDateFormat } from 'lib/dateFormat'
import getEventImage from 'lib/getEventImage'
import formatImgproxyUrl from 'lib/formatImgproxyUrl'
import { useIntl } from 'react-intl'
import IMAGE_TYPE from '@/enums/image-type'
import EventDto from '@/interfaces/gql/EventDto'
import ArtistDto from '@/interfaces/gql/ArtistDto'
import seo from '@/messages/seo'
import useLocalisedAreaName from '@/hooks/useLocalisedAreaName'
import useSeo from '@/hooks/useSeo'
import { useServerTime } from '@/context/ServerTimeContext'
import dateFormats from '@/components/generic/date/date-formats'

const MAX_LINEUP_LENGTH = 70
const MAX_OPEN_GRAPH_DESCRIPTION_LENGTH = 155

// Facebook recommends 1200x630 images\
// https://developers.facebook.com/docs/sharing/webmasters/images/
const OG_IMAGE_WIDTH = 1200
const OG_IMAGE_HEIGHT = 630

const useSeoMessages = () => {
  const intl = useIntl()

  const getEventTitle = (data: EventDto, region: string) => {
    const {
      title,
      date,
      startTime,
      isTicketed,
      venue: { name, area },
    } = data

    const safeAreaName = region ?? getAreaSafeName(area, area.country)
    const dateNow = new Date()
    const eventDate = new Date(startTime || date)

    if (isTicketed && eventDate >= dateNow) {
      return intl.formatMessage(seo.ticketedEventTitle, {
        title,
        name,
        safeAreaName,
      })
    }
    return intl.formatMessage(seo.nonTicketedEventTitle, {
      title,
      name,
      safeAreaName,
    })
  }

  const getEventTitleForSharing = (data: EventDto, region: string) => {
    const {
      title,
      date,
      startTime,
      isTicketed,
      venue: { name, area },
    } = data

    const safeAreaName = region ?? getAreaSafeName(area, area.country)
    const dateNow = new Date()
    const eventDate = new Date(startTime || date)

    if (isTicketed && eventDate >= dateNow) {
      return intl.formatMessage(seo.ticketedEventTitleForSharing, {
        title,
        name,
        safeAreaName,
      })
    }

    if (getYear(eventDate) < getYear(dateNow)) {
      const year = getYear(eventDate)
      return intl.formatMessage(seo.nonTicketedEventDateTitleForSharing, {
        title,
        name,
        safeAreaName,
        year,
      })
    }

    return intl.formatMessage(seo.defaultEventTitleForSharing, {
      title,
      name,
      safeAreaName,
    })
  }

  const getLineup = (artists: readonly ArtistDto[]) => {
    let result = ''

    if (arrayHasData(artists)) {
      const andMore = intl.formatMessage(seo.lineupAndMore)

      artists.forEach((artist, index) => {
        if (index === 0) {
          result += artist.name
        } else if (result.length + artist.name.length <= MAX_LINEUP_LENGTH) {
          result += `, ${artist.name}`
        } else if (!result.endsWith(andMore)) {
          result += ` ${andMore}`
        }
      })
    } else {
      result = intl.formatMessage(seo.lineupTBA)
    }

    return result
  }

  const getEventDescription = (data: EventDto) => {
    const { startTime, endTime, date, artists, isTicketed } = data
    const eventDate = getEventDate(startTime, endTime, date)
    const lineup = getLineup(artists)

    const result = intl.formatMessage(seo.eventDescriptionLineup, {
      eventDate,
      lineup,
    })

    if (isTicketed) {
      return intl.formatMessage(seo.ticketedEventDescription, {
        result,
      })
    }

    return result
  }

  return {
    getEventTitle,
    getEventTitleForSharing,
    getLineup,
    getEventDescription,
  }
}

const getEventDate = (startTime: Date, endTime: Date, date: Date) => {
  const start = toDateFormat(startTime || date)
  const end = toDateFormat(endTime || date)
  const startYear = getYear(start)
  const endYear = getYear(end)

  let result = formatDate(start, dateFormats.Standard)

  if (isMultiDayEvent(start, end)) {
    const formattedEnd = formatDate(end, dateFormats.Standard)

    if (startYear !== endYear) {
      result += ` ${startYear}`
    }

    result += ` - ${formattedEnd}`
  }

  result += ` ${endYear}`

  return result
}

const useBuildEventSEO = (
  data: EventDto,
  overrides?: { title?: string; description?: string }
) => {
  const {
    venue: { area },
    content,
    embargoDate,
    live,
  } = data

  const serverTime = useServerTime()

  const region = useLocalisedAreaName({ area, country: area.country })
  const seoImages = []

  let ogDescription = content

  const { flyerFront, images, newEventForm } = data
  const flyerFrontUrl = getEventImage(
    { flyerFront, images, newEventForm },
    IMAGE_TYPE.flyerFront
  )

  const { getEventTitle, getEventTitleForSharing, getEventDescription } =
    useSeoMessages()

  if (flyerFrontUrl) {
    const src = formatImgproxyUrl(flyerFrontUrl, {
      rt: 'fill',
      h: OG_IMAGE_HEIGHT,
      w: OG_IMAGE_WIDTH,
      quality: 50,
    })
    seoImages.push({
      url: src,
      width: OG_IMAGE_WIDTH,
      height: OG_IMAGE_HEIGHT,
    })
  }

  if (ogDescription?.length > MAX_OPEN_GRAPH_DESCRIPTION_LENGTH) {
    ogDescription = `${content.substr(
      0,
      MAX_OPEN_GRAPH_DESCRIPTION_LENGTH - 3
    )}...`
  }

  return useSeo({
    overrides: {
      title: overrides?.title || getEventTitle(data, region),
      description: overrides?.description || getEventDescription(data),
      ogTitle: getEventTitleForSharing(data, region),
      ogDescription,
      images: seoImages,
      noindex: !live || new Date(embargoDate).getTime() > serverTime,
    },
  })
}

export {
  useBuildEventSEO,
  useSeoMessages,
  getEventDate,
  MAX_LINEUP_LENGTH,
  MAX_OPEN_GRAPH_DESCRIPTION_LENGTH,
  OG_IMAGE_WIDTH,
  OG_IMAGE_HEIGHT,
}
