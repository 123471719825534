import InternalServerError from '@/components/error-pages/500'
import ThrowStatusCode from '@/components/shared/throw-status-code'
import { STATUS_CODES } from '@/components/request-logging'

const Throw500 = () => (
  <ThrowStatusCode
    statusCode={STATUS_CODES.INTERNAL_SERVER_ERROR}
    ErrorComponent={InternalServerError}
  />
)

export default Throw500
