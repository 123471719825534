import { gql } from '@apollo/client'

const GET_EVENT_FOR_MIXPANEL_PROPERTIES = gql`
  query GET_EVENT_FOR_MIXPANEL_PROPERTIES($id: ID!) {
    event(id: $id) {
      id
      title
      flyerFront
      flyerBack
      newEventForm
      images {
        id
        filename
        alt
        type
        crop
      }
      startTime
      endTime
      isTicketed
      isFestival
      resaleActive
      interestedCount
      venue {
        id
        name
        tba
        area {
          id
          name
          country {
            id
            name
          }
        }
      }
      area {
        id
        ianaTimeZone
      }
      promoters {
        id
        name
        hasTicketAccess
        user {
          id
        }
      }
      artists {
        id
        name
      }
      pick {
        id
        blurb
      }
      admin {
        id
      }
      playerLinks {
        id
      }
      revisions {
        id
        status
      }
      setTimes {
        id
        status
      }
    }
  }
`

export default GET_EVENT_FOR_MIXPANEL_PROPERTIES
