import PropTypes from 'prop-types'
import { IconButton, XIcon, variants } from '@resident-advisor/design-system'
import { TwitterShareButton } from 'react-share'

const Twitter = ({ iconVariant, shareUrl }) => (
  <TwitterShareButton url={shareUrl}>
    <IconButton as="div" variant={iconVariant} Icon={XIcon} />
  </TwitterShareButton>
)

Twitter.defaultProps = {
  iconVariant: variants.iconButton.primary,
}

Twitter.propTypes = {
  iconVariant: PropTypes.string,
  shareUrl: PropTypes.string.isRequired,
}

export default Twitter
