import {
  BandcampIcon,
  SoundcloudIcon,
  SpotifyIcon,
  AppleIcon,
  YoutubeIcon,
} from '@resident-advisor/design-system'

const audioServices = Object.freeze({
  BANDCAMP_TRACK: 1,
  SOUNDCLOUD_TRACK: 2,
  SPOTIFY_ALBUM: 3,
  APPLE_MUSIC: 4,
  YOUTUBE: 5,
  BANDCAMP_ALBUM: 6,
  SOUNDCLOUD_PLAYLIST: 7,
  FULL_MEDIA_URL: 8,
})

const convertServiceToIcon = (service) => {
  switch (parseInt(service.id)) {
    case audioServices.BANDCAMP_TRACK:
    case audioServices.BANDCAMP_ALBUM: {
      return BandcampIcon
    }

    case audioServices.SOUNDCLOUD_TRACK:
    case audioServices.SOUNDCLOUD_PLAYLIST: {
      return SoundcloudIcon
    }

    case audioServices.SPOTIFY_ALBUM: {
      return SpotifyIcon
    }

    case audioServices.APPLE_MUSIC: {
      return AppleIcon
    }

    case audioServices.YOUTUBE: {
      return YoutubeIcon
    }

    default: {
      return null
    }
  }
}

export { convertServiceToIcon }
export default audioServices
