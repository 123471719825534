import { gql } from '@apollo/client'

const GET_ORDER_NUMBER = gql`
  query GET_ORDER_NUMBER(
    $eventId: String
    $orderBy: [Ticketing_OrderOrderByWithRelationInput!]
  ) {
    Ticketing_me {
      id
      orders(eventId: $eventId, orderBy: $orderBy) {
        id
        orderNumber
        createdAt
      }
    }
  }
`

export default GET_ORDER_NUMBER
