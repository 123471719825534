import { gql } from '@apollo/client'

const GET_EVENT_METADATA_OVERRIDES_QUERY = gql`
  query GET_EVENT_METADATA_OVERRIDES_QUERY($where: JSON!) {
    metadataOverrides(where: $where) {
      id
      title
      description
    }
  }
`

export default GET_EVENT_METADATA_OVERRIDES_QUERY
