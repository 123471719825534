import PropTypes from 'prop-types'
import { useIntl } from 'react-intl'
import {
  Alignment,
  Divider,
  Text,
  Grid,
  Column,
  variants,
} from '@resident-advisor/design-system'
import messages from '@/messages/events'
import CmsContent from '@/components/generic/cms-content'
import Person from '@/components/generic/person'

const EventPick = ({ text, author }) => {
  const intl = useIntl()
  return (
    <Alignment mb={5} flexDirection="column">
      <Divider borderBottomWidth="3px" mb={2} borderColor="accent" />
      <Grid sCols={1} mCols={1} lCols={2} sWrap mWrap>
        <Column>
          <Text variant={variants.text.heading.l} color="accent" my={2}>
            {intl.formatMessage(messages.raPick).toUpperCase()}
          </Text>
        </Column>
        <Column>
          <CmsContent textVariant={variants.text.heading.l} content={text} />
          {author?.name && (
            <Person
              name={author.name}
              imageUrl={author.imageUrl}
              username={author.username}
              contributor={author.contributor}
              pt={3}
            />
          )}
        </Column>
      </Grid>
    </Alignment>
  )
}

EventPick.propTypes = {
  author: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    name: PropTypes.string,
    username: PropTypes.string,
    imageUrl: PropTypes.string,
    contributor: PropTypes.bool,
  }),
  text: PropTypes.string,
}

export default EventPick
