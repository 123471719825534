import PropTypes from 'prop-types'
import { useRouter } from 'next/router'
import { getRelativeUri } from 'lib/utils'
import { useQueryResult } from '@/components/generic/query-result-handler'
import Seo from '@/components/generic/seo/Seo'
import { useBuildEventSEO } from './seoUtils'
import GET_EVENT_METADATA_OVERRIDES_QUERY from './GetEventMetadataOverridesQuery'

const propTypes = {
  data: PropTypes.shape({
    startTime: PropTypes.date,
    endTime: PropTypes.date,
    date: PropTypes.date,
    embargoDate: PropTypes.date,
    live: PropTypes.bool,
    area: PropTypes.shape({
      country: PropTypes.shape({}),
    }),
    isTicketed: PropTypes.bool,
    venue: PropTypes.shape({}),
    artists: PropTypes.arrayOf(PropTypes.shape({})),
  }),
  overrides: PropTypes.shape({
    title: PropTypes.string,
    description: PropTypes.string,
  }),
}

const EventSEO = ({ data }) => {
  const { asPath } = useRouter()
  const { data: metadataOverrides, loading } = useQueryResult(
    GET_EVENT_METADATA_OVERRIDES_QUERY,
    {
      variables: { where: { url: getRelativeUri(asPath) } },
      dataKey: 'metadataOverrides',
    }
  )

  if (loading) {
    return null
  }

  const override = metadataOverrides?.[0]

  return (
    <EventSEOMarkup
      data={data}
      overrides={{
        title: override?.title,
        description: override?.description,
      }}
    />
  )
}

EventSEO.propTypes = propTypes

const EventSEOMarkup = ({ data, overrides }) => {
  return <Seo {...useBuildEventSEO(data, overrides)} />
}

EventSEOMarkup.propTypes = propTypes

export default EventSEO
