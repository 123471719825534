interface UseEventMangementUrlParameters {
  eventId: string
  queryString?: string
  newEventForm: boolean
}

const useCreateEventManagementUrl =
  () =>
  ({ eventId, queryString, newEventForm }: UseEventMangementUrlParameters) => {
    if (!newEventForm) {
      let url = `/pro/event-revisions.aspx?id=${eventId}`

      if (queryString) {
        url = `${url}&${queryString}`
      }

      return url
    }

    let url = `/pro/events/${eventId}`

    if (queryString) {
      url = `${url}?${queryString}`
    }

    return url
  }

const useEventManagementUrl = (options: UseEventMangementUrlParameters) => {
  const createEventManagementUrl = useCreateEventManagementUrl()

  return createEventManagementUrl(options)
}

export { useEventManagementUrl, useCreateEventManagementUrl }
