import {
  Alignment,
  Box,
  Button,
  Icon,
  SubSection,
  TicketIcon,
  variants,
} from '@resident-advisor/design-system'
import { motion } from 'framer-motion'
import { useInView } from 'react-intersection-observer'
import dynamic from 'next/dynamic'
import { useState } from 'react'
import { useIntl } from 'react-intl'
import { padding } from '@/themes'
import dict from '@/messages/dict'
import {
  PerformanceTrackingTypes,
  useManualPerformanceTracking,
} from '@/context/PerformanceContext/usePerformanceTracking'
import useIsMobile from '@/hooks/useIsMobile'
import BrunchTicketFilter from '@/components/events/pages/event-detail/event-tickets/brunch-event-tickets/brunch-ticket-filter'
import EventTicketBar from '../event-ticket-bar'
import {
  BrunchTicketType,
  BrunchVIPTicketType,
  SelectedTicketType,
  stringToBrunchTicketType,
} from './helpers/BrunchTicketTypes'
import { getBrunchEventIdsForTicketType } from './helpers/BrunchChildEventMappings'
import useMultiIframeMessageHandling from './useMultiIframeMessageHandling'
import { BrunchEvent, brunchEventIsSoldOut } from './helpers/BrunchEvent'
import getDefaultSelectedEventId from './helpers/getDefaultSelectedEventId'
import BrunchTicketSection from './brunch-ticket-section/BrunchTicketSection'

// Dynamically importing this so auto-showing for mobile works
// as it needs to work out the height from the client
const BrunchTicketsModal = dynamic(
  () => import('./brunch-tickets-modal/BrunchTicketsModal'),
  {
    ssr: false,
  }
)
BrunchTicketsModal.displayName = 'BrunchTicketsModal'

const TICKET_PANEL_ID = 'tickets'
const DEFAULT_TICKET_TYPE: BrunchTicketType = BrunchTicketType.THURSDAY
const DEFAULT_VIP_TICKET_TYPE: BrunchVIPTicketType =
  BrunchVIPTicketType.THURSDAY_VIP

type BrunchEventTicketsProps = {
  ticketType?: string
  brunchEvents: BrunchEvent[]
}

const BrunchEventTickets = ({
  ticketType: ticketTypeQuery,
  brunchEvents,
}: BrunchEventTicketsProps) => {
  const [selectedTicketType, setSelectedTicketType] =
    useState<SelectedTicketType>(
      stringToBrunchTicketType(ticketTypeQuery) || {
        baseTicketType: DEFAULT_TICKET_TYPE,
        vipTicketType: DEFAULT_VIP_TICKET_TYPE,
      }
    )

  const [iframe1Ref, setIframe1Ref] = useState(null)
  const [iframe2Ref, setIframe2Ref] = useState(null)
  const intl = useIntl()

  const displayedEvents = getEventsToDisplay(
    selectedTicketType.baseTicketType,
    brunchEvents
  )
  const defaultSelectedEventId = getDefaultSelectedEventId(displayedEvents)

  const [ref, inView] = useInView()
  const isMobile = useIsMobile()
  const { onBuyTickets } = useMultiIframeMessageHandling(
    defaultSelectedEventId,
    {
      eventId: displayedEvents.baseTicketEvent.id,
      ref: iframe1Ref,
    },
    {
      eventId: displayedEvents.vipTicketEvent.id,
      ref: iframe2Ref,
    }
  )

  // We should skip tracking if the device is mobile in this case
  // since the iframe is not loaded on mobile when the user lands on the page
  const onLoad = useManualPerformanceTracking(
    PerformanceTrackingTypes.LoadEventDetailsTicketed,
    'ticketsIframe',
    isMobile
  )

  return (
    <>
      <Box display={{ s: 'none', m: 'block' }}>
        <SubSection py={3} variant={variants.section.primary}>
          <BrunchTicketFilter
            ticketType={selectedTicketType.baseTicketType}
            isMobile={false}
            onFilterChange={setSelectedTicketType}
          />
        </SubSection>
        <SubSection py={padding.pt} variant={variants.section.translucent}>
          {/* Base ticket */}
          <Box
            width={{ s: '100%', m: '680px' }} // match width of ticket tiers in iframe https://github.com/resident-advisor/ra-web/blob/a95d2c04d222f0c5d22c574c54ef2e96ec02f3d9/RA/css/next/widget.css#L637
            id={TICKET_PANEL_ID}
            ref={ref}
            pb={4}
          >
            <BrunchTicketSection
              event={displayedEvents.baseTicketEvent}
              id="m"
              ticketType={selectedTicketType.baseTicketType}
              defaultSelectedEventId={defaultSelectedEventId}
              onLoad={(x) => {
                onLoad()
                setIframe1Ref(x)
              }}
            />
            <BrunchTicketSection
              event={displayedEvents.vipTicketEvent}
              id="vip"
              ticketType={selectedTicketType.vipTicketType}
              defaultSelectedEventId={defaultSelectedEventId}
              onLoad={(x) => {
                onLoad()
                setIframe2Ref(x)
              }}
            />
            <Alignment flexDirection="row" justifyContent="end">
              <Button width="200px" onClick={() => onBuyTickets()}>
                <Icon Component={TicketIcon} mr={2} />
                {intl.formatMessage(dict.payNow)}
              </Button>
            </Alignment>
          </Box>
        </SubSection>
        <motion.div initial="hidden" animate={inView ? 'hidden' : 'visible'}>
          <EventTicketBar
            eventId={displayedEvents.baseTicketEvent.id}
            ticketPanelId={`#${TICKET_PANEL_ID}`}
          />
        </motion.div>
      </Box>

      <Box display={{ s: 'block', m: 'none' }}>
        <BrunchTicketsModal
          events={displayedEvents}
          ticketPanelId={`#${TICKET_PANEL_ID}`}
          selectedTicketType={selectedTicketType}
          handleTicketTypeUpdate={setSelectedTicketType}
        />
      </Box>
    </>
  )
}

const getEventsToDisplay = (
  ticketType: BrunchTicketType,
  brunchEvents: BrunchEvent[]
): { baseTicketEvent: BrunchEvent; vipTicketEvent: BrunchEvent } => {
  const eventIds = getBrunchEventIdsForTicketType(ticketType)
  const baseEvent = brunchEvents.find((event) => event.id === eventIds[0])
  const vipEvent = brunchEvents.find((event) => event.id === eventIds[1])

  const baseTicketEvent = {
    ...baseEvent,
    isSoldOut: brunchEventIsSoldOut(baseEvent.standardTickets),
    isVip: false,
  }
  const vipTicketEvent = {
    ...vipEvent,
    isSoldOut: brunchEventIsSoldOut(vipEvent.standardTickets),
    isVip: true,
  }
  return { baseTicketEvent, vipTicketEvent }
}

export default BrunchEventTickets
