import { addMonths, isAfter } from 'date-fns'
import arrayHasData from 'lib/arrayHasData'
import { toDateFormat } from 'lib/dateFormat'
import { useMemo } from 'react'
import EventDto from '@/interfaces/gql/EventDto'
import TicketDto from '@/interfaces/gql/TicketDto'
import UserOrderDto from '@/interfaces/gql/UserOrderDto'
import TICKETING_SYSTEM, { TicketingSystem } from '@/enums/ticketing-system'
import { useTicketsPromoCode } from '@/components/events/pages/event-detail/event-tickets'

const useShouldShowEventTickets = (
  userOrders: UserOrderDto[],
  childEvents: EventDto[],
  isTicketed: boolean,
  date: Date,
  tickets: TicketDto[],
  ticketingSystem?: TicketingSystem
) => {
  const isNewAPITickets = ticketingSystem === TICKETING_SYSTEM.entire
  const ticketsPromoCode = useTicketsPromoCode({ ticketingSystem })
  // Tickets could be unhidden by a promo code in the URL, or having purchased a deposit ticket
  const ticketsMightBeUnhidden = !!(
    ticketsPromoCode || arrayHasData(userOrders)
  )

  const isParentAndShouldShowTicketWidget =
    shouldShowWidgetOnParentEvent(childEvents)

  const result = useMemo(
    () =>
      ((isTicketed || isParentAndShouldShowTicketWidget) &&
        isAfter(toDateFormat(date), addMonths(Date.now(), -1)) &&
        (arrayHasData(tickets) ||
          isParentAndShouldShowTicketWidget ||
          ticketsMightBeUnhidden)) ||
      isNewAPITickets,
    [
      date,
      isNewAPITickets,
      isTicketed,
      tickets,
      isParentAndShouldShowTicketWidget,
      ticketsMightBeUnhidden,
    ]
  )

  return result
}

const shouldShowWidgetOnParentEvent = (childEvents) => {
  return (
    arrayHasData(childEvents) &&
    childEvents.some((event) => event.isTicketed) &&
    childEvents.some((event) =>
      isAfter(toDateFormat(event.date), addMonths(Date.now(), -1))
    )
  )
}

export default useShouldShowEventTickets
