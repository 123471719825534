import React, { FC } from 'react'
import {
  Button,
  Alignment,
  Box,
  Icon,
  TicketIcon,
  variants,
} from '@resident-advisor/design-system'
import { useIntl } from 'react-intl'
import messages from '@/messages/payment'
import Link from '@/components/generic/link'
import { Break } from '@/messages/formatters'
import testIds from '@/enums/testIds'

type Props = {
  onViewTickets: () => void
}

const CtaBlock: FC<Props> = ({ onViewTickets }) => {
  const intl = useIntl()

  return (
    <Alignment
      flexDirection="column"
      bg="black"
      bottom={0}
      left={0}
      pt={{ s: 0, m: 2 }}
      pb={{ s: 4, m: 3 }}
      px={2}
    >
      <Box display={{ s: 'block', m: 'none' }}>
        <Button
          data-testid={testIds.buttonLink}
          style={{ width: '100%' }}
          onClick={onViewTickets}
        >
          <Icon size={24} color="white" Component={TicketIcon} mr={1} />
          {intl.formatMessage(messages.viewOrderTickets)}
        </Button>
      </Box>

      <Alignment
        mt={{ s: 2, m: 3 }}
        mx="auto"
        flexDirection="column"
        alignItems="center"
        css={{ textAlign: 'center' }}
      >
        <Link variant={variants.text.small} href="/about/community" underline>
          {intl.formatMessage(messages.learnHowRaSupportElectronic, {
            break: Break,
          })}
        </Link>
      </Alignment>
    </Alignment>
  )
}

export default CtaBlock
