import PropTypes from 'prop-types'
import { useState, useEffect } from 'react'
import {
  IconButton,
  ShareIcon,
  variants,
} from '@resident-advisor/design-system'

const WebShare = ({ iconVariant, shareUrl }) => {
  const [canShare, setCanShare] = useState(false)

  useEffect(() => {
    setCanShare(!!window.navigator.share)
  }, [])

  const onClick = () => {
    window.navigator.share({
      url: shareUrl,
    })
  }

  if (!canShare) {
    return null
  }

  return <IconButton variant={iconVariant} Icon={ShareIcon} onClick={onClick} />
}

WebShare.defaultProps = {
  iconVariant: variants.iconButton.primary,
}

WebShare.propTypes = {
  iconVariant: PropTypes.string,
  shareUrl: PropTypes.string.isRequired,
}

export default WebShare
