import { useIntl } from 'react-intl'
import { DOMNode, domToReact, Element } from 'html-react-parser'
import {
  SlashHeader,
  variants,
  Text,
  Alignment,
} from '@resident-advisor/design-system'
import { djBySlugRoute } from 'lib/routes'
import Link from '@/components/generic/link'
import CmsContent from '@/components/generic/cms-content'
import messages from '@/messages/events'
import trackingIds from '@/tracking/tracking-ids'
import ArtistDto from '@/interfaces/gql/ArtistDto'
import SetTimesDto from '@/interfaces/gql/SetTimesDto'
import SET_TIMES_STATUS_TYPE from '@/enums/set-times-status-type'
import pro from '@/messages/pro'

const Lineup: React.FC<LineupProps> = ({
  lineup,
  artists,
  setTimes,
}: LineupProps) => {
  const intl = useIntl()

  const lineupReplace = getLineupReplace(artists)

  const showSetTimes =
    setTimes.status === SET_TIMES_STATUS_TYPE.public && setTimes.lineup

  return (
    <div data-tracking-id={trackingIds.eventDetailLineup}>
      <SlashHeader pb={setTimes.status === SET_TIMES_STATUS_TYPE.set ? 0 : 3}>
        {showSetTimes
          ? intl.formatMessage(pro.setTimes)
          : intl.formatMessage(messages.lineup)}
      </SlashHeader>
      <Alignment flexDirection="column">
        {setTimes.status === SET_TIMES_STATUS_TYPE.set && (
          <Text pb={3}>
            {intl.formatMessage(messages.setTimesWillBeAvailable)}
          </Text>
        )}
        <CmsContent
          replace={lineupReplace}
          textVariant={getTextVariant(lineup)}
          content={showSetTimes ? setTimes.lineup : lineup}
          textProps={{ color: 'secondary', dataTestId: 'lineup' }}
        />
      </Alignment>
    </div>
  )
}

type LineupProps = {
  lineup: string
  artists: Pick<ArtistDto, 'id' | 'urlSafeName'>[]
  setTimes: Pick<SetTimesDto, 'status' | 'lineup'>
}

/* eslint-disable react/destructuring-assignment */
// Casting required due to:
// https://github.com/remarkablemark/html-react-parser/issues/1126
const getLineupReplace =
  (artists: ArtistDto[]) => (node: DOMNode, textVariant: string) => {
    if (node.type === 'tag' && node.name === 'a') {
      return (
        <Link {...node.attribs} variant={textVariant}>
          {domToReact((node as Element).children as DOMNode[])}
        </Link>
      )
    }

    if (node.type === 'tag' && node.name === 'artist' && node.attribs.id) {
      const artist = artists.find((a) => a.id === node.attribs.id)
      if (artist) {
        return (
          <Link
            {...djBySlugRoute(`/dj/${artist.urlSafeName}`)}
            variant={textVariant}
          >
            {domToReact((node as Element).children as DOMNode[])}
          </Link>
        )
      }
    }

    return node
  }
/* eslint-enable react/destructuring-assignment */

const getTextVariant = (lineup: string) => {
  const numberOfArtists = lineup.split(NEWLINES_OR_COMMAS).length
  return numberOfArtists > MAX_LARGE_TEXT_ARTISTS
    ? variants.text.heading.m
    : variants.text.heading.l
}

const NEWLINES_OR_COMMAS = /[\n,]/
const MAX_LARGE_TEXT_ARTISTS = 6

export default Lineup
