const EVENT_REVISION_STATUS_TYPE = Object.freeze({
  pending: 'PENDING',
  archived: 'ARCHIVED',
  current: 'CURRENT',
  denied: 'DENIED',
})

type EventRevisionStatusType =
  typeof EVENT_REVISION_STATUS_TYPE[keyof typeof EVENT_REVISION_STATUS_TYPE]

export default EVENT_REVISION_STATUS_TYPE
export type { EventRevisionStatusType }
