import PropTypes from 'prop-types'
import {
  Box,
  SubSection,
  Section,
  Grid,
  Column,
  Text,
  variants,
} from '@resident-advisor/design-system'
import { useIntl } from 'react-intl'
import getEventImage from 'lib/getEventImage'
import styled from 'styled-components'
import arrayHasData from 'lib/arrayHasData'
import trackingIds from '@/tracking/tracking-ids'
import IMAGE_TYPE from '@/enums/image-type'
import messages from '@/messages/events'
import { FullWidthImage } from '@/components/generic/full-width'
import Link from '@/components/generic/link'

import EventPick from './EventPick'
import EventAdditionalInfo from './EventAdditionalInfo'
import EventMediaLinks from './EventMediaLinks'

const EventDescription = ({
  content,
  pick,
  flyerFront,
  flyerBack,
  title,
  resaleActive,
  admin,
  startTime,
  playerLinks,
  newEventForm,
  images,
  ...props
}) => {
  const intl = useIntl()

  const flyerFrontUrl = getEventImage(
    { flyerFront, images, newEventForm },
    IMAGE_TYPE.flyerFront
  )
  const flyerBackUrl = getEventImage(
    { flyerBack, images, newEventForm },
    IMAGE_TYPE.flyerBack
  )

  return (
    <Section
      data-tracking-id={trackingIds.eventDetailDescription}
      id={pick && 'event-description'}
    >
      <SubSection>
        {pick && <EventPick text={pick.blurb} author={pick.author} />}
        <Grid
          sCols={1}
          mCols={1}
          lCols={2}
          gridTemplateAreas={{ s: sGTA, l: lGTA }}
        >
          <Column area={Areas.Content}>
            <Box pt={{ s: 4, l: 0 }}>
              <BreakText multiLine variant={variants.text.longform}>
                {content}
              </BreakText>
            </Box>
            <EventAdditionalInfo
              {...props}
              newEventForm={newEventForm}
              admin={admin}
              startTime={startTime}
              title={title}
            />
            {arrayHasData(playerLinks) && (
              <Box mt={5}>
                <EventMediaLinks playerLinks={playerLinks} />
              </Box>
            )}
            {resaleActive && <AboutResale />}
          </Column>
          {flyerFrontUrl && (
            <Column area={Areas.Flyers}>
              <FullWidthImage
                src={flyerFrontUrl}
                alt={`${title} - ${intl.formatMessage(messages.flyerFront)}`}
                sizes={['s']}
                imageSizes={{ s: '100vw', m: '100vw', l: '50vw' }}
                width="100%"
              />
              {flyerBackUrl && (
                <FullWidthImage
                  src={flyerBackUrl}
                  alt={`${title} - ${intl.formatMessage(messages.flyerBack)}`}
                  sizes={['s']}
                  imageSizes={{ s: '100vw', m: '100vw', l: '50vw' }}
                  width="100%"
                  spacing={{ mt: 1 }}
                />
              )}
            </Column>
          )}
        </Grid>
      </SubSection>
    </Section>
  )
}

EventDescription.propTypes = {
  content: PropTypes.string,
  pick: PropTypes.object,
  flyerFront: PropTypes.string,
  flyerBack: PropTypes.string,
  newEventForm: PropTypes.bool,
  images: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      filename: PropTypes.string.isRequired,
      alt: PropTypes.string,
      type: PropTypes.oneOf(Object.values(IMAGE_TYPE)),
      crop: PropTypes.object,
    })
  ),
  resaleActive: PropTypes.bool,
  title: PropTypes.string,
  admin: PropTypes.object,
  startTime: PropTypes.string,
  playerLinks: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      sourceId: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
        .isRequired,
      audioService: PropTypes.shape({
        id: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
          .isRequired,
        name: PropTypes.string.isRequired,
      }).isRequired,
    })
  ),
}

const lGTA = `
  "content flyers"
`

const sGTA = `
  "flyers"
  "content"
`

const Areas = {
  Content: 'content',
  Flyers: 'flyers',
}

const BreakText = styled(Text)`
  word-break: break-word;
`

const AboutResale = () => {
  const intl = useIntl()
  return (
    <Box pt={{ s: 4, m: 5 }}>
      <Text
        display="block"
        mb={4}
        variant={variants.text.heading.m}
        color="accent"
      >
        {intl.formatMessage(messages.aboutResale)}
      </Text>
      <Text display="block" mb={4}>
        {intl.formatMessage(messages.eventSoldOutResaleActive, {
          resaleactivelink,
        })}
      </Text>
      <Text display="block" mb={4}>
        {intl.formatMessage(
          messages.checkBackToPurchaseTicketsAsTheyBecomeAvailable
        )}
      </Text>
      <Text>{intl.formatMessage(messages.resaleCopy)}</Text>
    </Box>
  )
}

const resaleactivelink = (val) => (
  <Link href="/tickets/resale" underline>
    {val}
  </Link>
)

export default EventDescription
