import arrayHasData from 'lib/arrayHasData'
import { zonedTimeToUtc } from 'date-fns-tz'
import { useQueryResult } from '@/components/generic/query-result-handler'
import EVENT_REVISION_STATUS_TYPE from '@/enums/event-revision-status-type'
import GET_EVENT_FOR_MIXPANEL_PROPERTIES from '@/components/events/pages/event-detail/GetEventForMixpanelPropertiesQuery'
import { usePersonalizationContext } from '@/context/PersonalizationContext'

const useEventDetailMixpanelProperties = (
  id,
  ticketsPromoCode,
  shouldShowEventTickets
) => {
  const { data, loading } = useQueryResult(GET_EVENT_FOR_MIXPANEL_PROPERTIES, {
    variables: {
      id,
    },
    dataKey: 'event',
  })
  const { area: userArea } = usePersonalizationContext()

  if (loading) return { loading }

  const startTimeUTC = Date.parse(
    zonedTimeToUtc(data?.startTime, data?.area?.ianaTimeZone)
  )
  const endTimeUTC = Date.parse(
    zonedTimeToUtc(data?.endTime, data?.area?.ianaTimeZone)
  )

  return {
    data: {
      'Event Name': data?.title,
      'Primary Event Promoter': arrayHasData(
        data?.promoters?.filter((p) => p?.user?.id === data?.admin?.id)
      )
        ? data?.promoters
            ?.filter((p) => p?.user?.id === data?.admin?.id)[0]
            ?.id.toString()
        : '',
      'All Event Promoters': arrayHasData(data?.promoters)
        ? data?.promoters?.reduce((acc, promoter) => [...acc, promoter.id], [])
        : [],
      'Event ID': data?.id?.toString(),
      'Event Date': data?.startTime,
      'Event Venue': data?.venue?.id?.toString(),
      'Event Region Id': data?.venue?.area?.id,
      'Event Region Name': data?.venue?.area?.name,
      'Event Country Id': data?.venue?.area?.country?.id,
      'Event Country Name': data?.venue?.area?.country?.name,
      'Event Revision': arrayHasData(
        data?.revisions?.filter(
          (r) => r.status === EVENT_REVISION_STATUS_TYPE.current
        )
      )
        ? data?.revisions
            ?.filter((r) => r.status === EVENT_REVISION_STATUS_TYPE.current)[0]
            ?.id.toString()
        : '',
      'Promo Code': ticketsPromoCode || '',
      'Ticketed Event': data?.isTicketed,
      // 'Sold Out':,
      'Tickets on sale': shouldShowEventTickets,
      // 'Child Events':,
      // 'Deposit Tickets':,
      // 'Shipping Options':,
      // 'Add Ons':,
      'RA Pick': !!data?.pick?.id,
      'Lineup Artist Names': arrayHasData(data?.artists)
        ? data?.artists?.reduce((acc, artist) => [...acc, artist.name], [])
        : [],
      'Lineup Artist IDs': arrayHasData(data?.artists)
        ? data?.artists?.reduce((acc, artist) => [...acc, artist.id], [])
        : [],
      'Media Links Present': arrayHasData(data?.playerLinks),
      'Event Image Present': data?.flyerFront !== '' || data?.flyerBack !== '',
      'Venue TBA': data?.venue?.tba,
      'Resale Active': data?.resaleActive,
      'Different Region': data?.venue?.area?.id !== userArea?.id,
      'User Region Id': userArea?.id?.toString(),
      'User Region Name': userArea?.name,
      'Is Festival': data?.isFestival,
      'Interested Count': data?.interestedCount,
      'Set times status': data?.setTimes?.status,
      'Viewed during event':
        Date.now() > startTimeUTC && Date.now() < endTimeUTC,
    },
    loading: false,
  }
}

export default useEventDetailMixpanelProperties
