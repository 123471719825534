import PropTypes, { InferProps } from 'prop-types'
import styled, { ThemeProvider, css } from 'styled-components'
import { LayoutGroup } from 'framer-motion'
import { Box, Alignment, Layout } from '@resident-advisor/design-system'
import arrayHasData from 'lib/arrayHasData'
import { HTMLAttributeAnchorTarget, useEffect, useState } from 'react'
import debounce from 'lodash/debounce'
import getConfig from 'next/config'
import disableScrollbar from 'lib/disableScrollbar'
import { dark } from '@/themes'
import { MenuItem } from '@/interfaces/MenuItem'
import featureSwitches from '@/enums/feature-switches'
import useNavSelected from '@/hooks/useNavSelected'
import { useFeatureSwitch } from '@/context/FeatureSwitchesContext'
import useDimensions from '@/hooks/useDimensions'
import { useStickyContext } from '@/context/StickyContext'
import { useMenuContext } from '@/context/MenuContext'
import { hrefAsPropTypes } from '@/components/generic/link'
import SingleSubNavItem from './SingleSubNavItem'
import MenuSubNavItem from './MenuSubNavItem'

const { publicRuntimeConfig } = getConfig()

const SubNav: React.FC<SubNavProps> = ({
  subNavItems,
  trackingId,
  subNavFilters,
  theme = dark,
}) => {
  const [ref, { height }] = useDimensions()
  const { setOffset } = useStickyContext()
  const { selected } = useNavSelected(subNavItems, 0)
  const [hovered, setHovered] = useState(null)
  const enableNewMainNav = useFeatureSwitch(featureSwitches.enableNewMainNav)
  const [{ globalNavHeight }] = useMenuContext()

  const setHoveredState = debounce((value: number | null) => {
    setHovered(value)
  }, publicRuntimeConfig.SUBNAV_DEBOUNCE_DELAY)

  const hasFilters = arrayHasData(subNavFilters)
  const hasMenuItems = arrayHasData(subNavItems)

  useEffect(() => {
    // Sticky elements should appear below the subnav
    setOffset(height)
  }, [height, setOffset])

  return (
    <ThemeProvider theme={theme}>
      <Nav
        ref={ref}
        aria-label="Secondary"
        data-tracking-id={trackingId}
        enableNewMainNav={enableNewMainNav}
        globalNavHeight={globalNavHeight}
      >
        <Layout>
          <Alignment
            flexDirection={{ s: 'column', m: 'row' }}
            overflow="hidden"
            justifyContent={{
              s: 'flex-start',
              m: hasMenuItems ? 'space-between' : 'flex-end',
            }}
          >
            {hasMenuItems && (
              <LayoutGroup>
                <SubNavScrollArea
                  alignItems="center"
                  overflow={{ s: 'scroll', m: 'visible' }}
                >
                  {subNavItems.map((item, index) => {
                    return item.listMenuItems ? (
                      <MenuSubNavItem
                        item={item}
                        index={index}
                        selected={selected}
                        key={item.title}
                        hovered={hovered}
                        setHovered={setHoveredState}
                      />
                    ) : (
                      <SingleSubNavItem
                        theme={theme}
                        item={item}
                        index={index}
                        key={item.title}
                        selected={selected}
                        hovered={hovered}
                        setHovered={setHoveredState}
                      />
                    )
                  })}
                </SubNavScrollArea>
              </LayoutGroup>
            )}
            {hasFilters && (
              <Alignment>
                <SubNavScrollArea
                  alignItems="center"
                  pt={2}
                  pb={{ s: hasMenuItems ? 3 : 2, m: 2 }}
                  overflow="scroll"
                  flex={1}
                  as="menu"
                  aria-label="Filters"
                >
                  {subNavFilters.map((Filter, index) => (
                    <Box
                      // We can use indexes as keys here, since we have no suitable key data
                      // and subnav filters are passed in with a definite order that
                      // doesn't change between renders.
                      // eslint-disable-next-line react/no-array-index-key
                      key={index}
                      ml={{ s: 0, m: 2 }}
                      mr={{ s: 2, m: 0 }}
                      flexShrink={0}
                      as="li"
                    >
                      {Filter}
                    </Box>
                  ))}
                </SubNavScrollArea>
              </Alignment>
            )}
          </Alignment>
        </Layout>
      </Nav>
    </ThemeProvider>
  )
}

const SubNavPropTypes = {
  subNavItems: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      href: hrefAsPropTypes,
      as: hrefAsPropTypes,
      strikeThrough: PropTypes.bool,
      target: PropTypes.string,
      additionalSelectedPaths: PropTypes.arrayOf(PropTypes.string),
      listMenuItems: PropTypes.arrayOf(
        PropTypes.shape({
          title: PropTypes.string,
          href: hrefAsPropTypes,
          type: PropTypes.string,
        })
      ),
    })
  ),
  subNavFilters: PropTypes.arrayOf(PropTypes.node),
  trackingId: PropTypes.string.isRequired,
  theme: PropTypes.object,
}

interface SubNavMenuItem extends MenuItem {
  strikeThrough?: boolean
  target?: HTMLAttributeAnchorTarget
}

SubNav.propTypes = SubNavPropTypes
type SubNavProps = InferProps<
  Omit<typeof SubNavPropTypes, 'subNavItems'> & {
    subNavItems: SubNavMenuItem[]
  }
>

const Nav = styled.nav<{ enableNewMainNav: boolean; globalNavHeight: number }>`
  ${({ theme, enableNewMainNav, globalNavHeight }) =>
    css`
      z-index: ${theme.zIndex.subNav};
      background-color: ${theme.subNav.backgroundColor};
      position: sticky;
      top: ${enableNewMainNav ? `${globalNavHeight}px` : '0'};
    `};
`
// Fade out content at right edge of container
const fadeOutGradient = `
  linear-gradient(
    to left,
    rgba(0, 0, 0, 0) 0,
    rgba(0, 0, 0, 1) 1em
  )
`

const SubNavScrollArea = styled(Alignment)`
  list-style: none;

  ${disableScrollbar}

  ${({ theme }) => css`
    -webkit-mask: ${fadeOutGradient};
    mask: ${fadeOutGradient};

    /**
     * Only fade out content on mobile, since desktop 
     * content can be aligned to the right and will always
     * appear faded in that case
     */
    @media (min-width: ${theme.breakpoints.m}) {
      -webkit-mask: none;
      mask: none;
    }
  `}
`

export type { SubNavProps, SubNavMenuItem }
export { SubNavScrollArea }
export default SubNav
