import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import { Box } from '@resident-advisor/design-system'

const PlayerWidgetContainer = ({
  aspectRatio,
  height,
  src,
  title,
  ...iframeProps
}) => {
  if (height) {
    return (
      <PositionedIframe
        width="100%"
        height={height}
        scrolling="no"
        frameBorder="no"
        src={src}
        title={title}
        {...iframeProps}
      />
    )
  }

  return (
    <Box width="100%" pb={aspectRatio} position="relative">
      <PositionedIframe
        width="100%"
        height="100%"
        scrolling="no"
        frameBorder="no"
        src={src}
        title={title}
        aspectRatio={aspectRatio}
        {...iframeProps}
      />
    </Box>
  )
}

const PositionedIframe = styled.iframe`
  ${({ aspectRatio }) =>
    aspectRatio &&
    css`
      position: absolute;
    `};
`

PlayerWidgetContainer.propTypes = {
  aspectRatio: PropTypes.string,
  height: PropTypes.number,
  src: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
}

PlayerWidgetContainer.defaultProps = {
  aspectRatio: '100%',
}

export default PlayerWidgetContainer
