import React, { FC, useEffect, useState } from 'react'
import styled from 'styled-components'
import {
  colors,
  Box,
  Alignment,
  variants,
  deviceWidths as media,
} from '@resident-advisor/design-system'
import getConfig from 'next/config'
import disableScrollbar from 'lib/disableScrollbar'
import detectPlatform from 'lib/detectPlatform'
import openDeepLink from 'lib/openDeepLink'
import { useRouter } from 'next/router'
import testIds from '@/enums/testIds'
import PLAYWRIGHT_TEST_IDS from '@/enums/playwright-test-ids'
import {
  DefaultLoadingSpinner,
  useQueryResult,
} from '@/components/generic/query-result-handler'
import Throw500 from '@/components/generic/throw-500'
import OrderInfo from '@/components/events/pages/event-detail/confirm-order/order-info/OrderInfo'
import NoAppBlock from '@/components/events/pages/event-detail/confirm-order/order-info/NoAppBlock'
import CtaBlock from '@/components/events/pages/event-detail/confirm-order/order-info/CTABlock'
import Modal from '@/components/generic/modal'
import { useUserContext } from '@/context/UserContext'
import CloseButton from '@/components/generic/close-button'
import AuthenticatedComponent from '@/components/auth/authenticated-component'
import GET_TICKETING_ORDER_DETAIL_QUERY from '@/components/user/pages/orders/GetTicketingOrderDetailQuery'
import OrderDetailDto from '@/components/user/pages/orders/OrderDetailDto'

const ConfirmOrderModal = () => {
  const { query, push, isReady } = useRouter()
  const [open, setOpen] = useState(false)
  useEffect(() => {
    if (isReady && !!query.orderNumber) {
      setOpen(true)
    }
  }, [isReady, query.orderNumber])

  const { loading } = useUserContext()

  const handleClose = () => {
    setOpen(false)

    const closeUrl = query.idOrCountry
      ? `/events/${query.idOrCountry}`
      : '/events'

    push(closeUrl)
  }

  if (loading || !open) {
    return null
  }

  return (
    <AuthenticatedComponent onAuthenticationFailed={() => null}>
      <ConfirmOrderModalMarkup
        orderNumber={query.orderNumber as string}
        handleClose={handleClose}
      />
    </AuthenticatedComponent>
  )
}

const ConfirmOrderModalMarkup = ({
  orderNumber,
  handleClose,
}: {
  orderNumber: string
  handleClose: () => void
}) => {
  const { data, loading, error, empty } = useQueryResult(
    GET_TICKETING_ORDER_DETAIL_QUERY,
    {
      variables: {
        orderNumber,
      },
      dataKey: 'Ticketing_me.order',
    }
  )

  if (!loading && empty) {
    return null
  }

  if (!loading && error) {
    return <Throw500 />
  }

  return (
    <Modal
      toggle={handleClose}
      alignItems="center"
      justifyContent="center"
      allowModalScrolling
    >
      {loading ? (
        <DefaultLoadingSpinner />
      ) : (
        <OrderMarkup handleClose={handleClose} order={data} />
      )}
    </Modal>
  )
}

type OrderMarkupProps = {
  order: OrderDetailDto
  handleClose?: () => void
}

const OrderMarkup: FC<OrderMarkupProps> = ({ handleClose, order }) => {
  const { id, title, venue, startTime, endTime } =
    order.orderItems[0]?.ticketTier.event.legacy ?? {}
  const handleViewTickets = () => {
    const { isIOS } = detectPlatform()

    const storeUrl = isIOS
      ? SUCCESS_ORDER_MODAL.APP_STORE_DOWNLOAD_LINK
      : SUCCESS_ORDER_MODAL.GOOGLE_PLAY_DOWNLOAD_LINK

    openDeepLink(
      SUCCESS_ORDER_MODAL.RA_GUIDE_DEEP_LINK.replace('(eventId)', id),
      storeUrl
    )
  }

  return (
    <ViewBox data-testid={testIds.confirmedOrderModal}>
      <Alignment
        flexDirection="column"
        height={{ s: '100%', m: 'auto' }}
        justifyContent={{ s: 'space-between', m: 'flex-start' }}
      >
        <Box mb={{ s: 4, m: 0 }}>
          <Alignment justifyContent="flex-end">
            <CloseButton
              data-testid={testIds.confirmedOrderModalCloseButton}
              data-pw-test-id={PLAYWRIGHT_TEST_IDS.confirmOrderCloseButton}
              variant={variants.button.tertiary}
              onClick={handleClose}
            />
          </Alignment>

          <OrderInfo
            title={title}
            venue={venue?.name ?? ''}
            startTime={startTime}
            endTime={endTime}
          />

          <NoAppBlock />
        </Box>

        <CtaBlock onViewTickets={handleViewTickets} />
      </Alignment>
    </ViewBox>
  )
}

const ViewBox = styled(Box)`
  height: 100%;
  background: ${colors.black};
  max-width: 480px;
  padding: 20px 20px 0 20px;
  overflow-y: auto;
  position: relative;
  ${disableScrollbar}
  ${media.m`
      height: auto;
  `}
`

const {
  publicRuntimeConfig: { SUCCESS_ORDER_MODAL },
} = getConfig()

export default ConfirmOrderModal
