import TICKET_VALID_TYPE from '@/enums/ticket-valid-type'
import { BrunchEvent } from './BrunchEvent'

const getDefaultSelectedEventId = (events: {
  baseTicketEvent: BrunchEvent
  vipTicketEvent: BrunchEvent
}) => {
  const baseEventHasValidTickets = events.baseTicketEvent.standardTickets.some(
    (ticket) => ticket.validType === TICKET_VALID_TYPE.valid
  )

  const vipEventHasValidTickets = events.vipTicketEvent.standardTickets.some(
    (ticket) => ticket.validType === TICKET_VALID_TYPE.valid
  )

  if (baseEventHasValidTickets) {
    return events.baseTicketEvent.id
  }

  if (vipEventHasValidTickets) {
    return events.vipTicketEvent.id
  }

  return ''
}

export default getDefaultSelectedEventId
