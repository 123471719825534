import {
  Layout,
  Alignment,
  Text,
  Box,
  variants,
} from '@resident-advisor/design-system'
import { useIntl } from 'react-intl'
import arrayHasData from 'lib/arrayHasData'
import messages from '@/messages/events'

import featureSwitches from '@/enums/feature-switches'
import { useFeatureSwitch } from '@/context/FeatureSwitchesContext'
import LinkButton from '@/components/generic/link-button'
import { useQueryResult } from '@/components/generic/query-result-handler'
import { useUserContext } from '@/context/UserContext'
import GET_ORDER_NUMBER from '@/components/events/pages/event-detail/tickets-purchased/GetOrderNumber'

const TicketsPurchased = ({
  userOrders,
}: {
  userOrders: { rAOrderNumber: string }[]
}) => {
  const enableNewMainNav = useFeatureSwitch(featureSwitches.enableNewMainNav)

  if (!arrayHasData(userOrders)) {
    return null
  }

  return (
    <TicketsPurchasedMarkup
      orderUrl={
        enableNewMainNav
          ? `/user/order/${userOrders?.[0].rAOrderNumber}`
          : `/shop/order/${userOrders?.[0].rAOrderNumber}`
      }
    />
  )
}

const TicketingTicketsPurchased = ({ eventId }: { eventId: string }) => {
  const { isAuthenticated } = useUserContext()
  const { data, loading, error, empty } = useQueryResult(GET_ORDER_NUMBER, {
    variables: {
      eventId,
      orderBy: {
        createdAt: 'desc',
      },
    },
    fetchPolicy: 'cache-and-network',
    dataKey: 'Ticketing_me.orders',
    skip: !isAuthenticated,
  })

  if (loading || error || empty) {
    return null
  }

  return (
    <TicketsPurchasedMarkup orderUrl={`/user/orders/${data[0].orderNumber}`} />
  )
}

const TicketsPurchasedMarkup = ({ orderUrl }: { orderUrl: string }) => {
  const intl = useIntl()

  return (
    <Layout>
      <Box mt={3} p={{ s: 3, m: 4 }} bg="grey.10">
        <Alignment
          flexDirection={{ s: 'column', m: 'row' }}
          justifyContent={{ s: 'initial', m: 'space-between' }}
          alignItems={{ s: 'flex-start', m: 'initial ' }}
        >
          <Text variant={variants.text.heading.m} mb={{ s: 3, m: 0 }}>
            {intl.formatMessage(messages.ticketspurchased)}
          </Text>
          <LinkButton href={orderUrl} variant={variants.button.secondary}>
            {intl.formatMessage(messages.viewyourorder)}
          </LinkButton>
        </Alignment>
      </Box>
    </Layout>
  )
}

export { TicketsPurchased, TicketingTicketsPurchased }
