import {
  Box,
  Icon,
  ArrowDownIcon,
  Popover,
  variants,
  Alignment,
  Tag,
} from '@resident-advisor/design-system'
import { useRef } from 'react'
import PropTypes, { InferProps } from 'prop-types'
import styled, { css } from 'styled-components'
import { useIntl } from 'react-intl'
import { zIndex } from '@/themes'
import animation from '@/themes/animation'
import pro from '@/messages/pro'
import SubNavMenuItemType from '@/enums/sub-nav-menu-item-type'
import Flip from '@/components/shared/animation/Flip'
import Link, { hrefAsPropTypes } from '@/components/generic/link'
import useGetHorizontalOffset from './useGetHorizontalOffset'
import SubNavLink from './SubNavLink'
import Underline from './Underline'

const MenuSubNavItem: React.FC<MenuSubNavItemProps> = ({
  item,
  index,
  selected,
  hovered,
  setHovered,
}) => {
  const boxRef = useRef(null)
  const arrowOriginRef = useRef(null)
  const intl = useIntl()

  // aligns arrow on popover with arrow on nav menu item
  const horizontalOffset = useGetHorizontalOffset(arrowOriginRef, boxRef)

  return (
    <Box
      ref={boxRef}
      onMouseEnter={() => setHovered(index)}
      onMouseLeave={() => setHovered(null)}
    >
      <Popover
        pt={1}
        arrowPosition="right"
        zIndex={zIndex.popoverItem}
        horizontalOffset={horizontalOffset}
        role="menu"
        data-testid="popover-menu"
        content={item.listMenuItems.map((listItem) => (
          <Alignment
            py={1}
            alignItems="center"
            position="relative"
            key={listItem.title}
          >
            <StyledLink
              href={listItem.href}
              variant={variants.text.small}
              display="flex"
            >
              {listItem.title}
              {listItem.type === SubNavMenuItemType.new && (
                <Alignment justifyContent="flex-end" ml={2}>
                  <Tag>{intl.formatMessage(pro.newTag)}</Tag>
                </Alignment>
              )}
            </StyledLink>
          </Alignment>
        ))}
      >
        <Box>
          <SubNavLink
            isSelected={index === selected}
            as={item.as}
            target={item.target}
            data-testid="subnavlink"
          >
            <Alignment>
              {item.title}
              <Flip animate={index === hovered ? 'open' : 'closed'}>
                <Icon
                  Component={ArrowDownIcon}
                  size={20}
                  ref={arrowOriginRef}
                />
              </Flip>
            </Alignment>
            {index === hovered && (
              <Underline
                layoutId="underline"
                transition={animation.default.closed}
                data-testid="underline"
              />
            )}
          </SubNavLink>
        </Box>
      </Popover>
    </Box>
  )
}

const MenuSubNavItemPropTypes = {
  item: PropTypes.shape({
    title: PropTypes.string.isRequired,
    href: hrefAsPropTypes,
    as: hrefAsPropTypes,
    target: PropTypes.string,
    additionalSelectedPaths: PropTypes.arrayOf(PropTypes.string),
    listMenuItems: PropTypes.arrayOf(
      PropTypes.shape({
        title: PropTypes.string,
        href: hrefAsPropTypes.isRequired,
        type: PropTypes.string,
      })
    ),
  }).isRequired,
  index: PropTypes.number.isRequired,
  selected: PropTypes.number,
  hovered: PropTypes.number,
  setHovered: PropTypes.func,
}

MenuSubNavItem.propTypes = MenuSubNavItemPropTypes
type MenuSubNavItemProps = InferProps<typeof MenuSubNavItemPropTypes>

const StyledLink = styled(Link)`
  ${({ theme }) =>
    css`
      color: ${theme.subNav.linkColor};
      width: 100%;
    `};
`

export default MenuSubNavItem
