import PropTypes from 'prop-types'
import { withTheme } from 'styled-components'
import { isMedium } from 'lib/checkScreenWidth'
import removeHashFromColor from 'lib/removeHashFromColor'
import capitalize from 'lodash/capitalize'
import useBodyWidth from '@/hooks/useBodyWidth'
import PlayerWidgetContainer from '@/components/shared/audio-embed/player-widget-container'
import testIds from '@/enums/testIds'

const BandcampPlayer = ({
  theme,
  sourceId,
  title,
  color,
  type,
  responsiveSizing,
}) => {
  const width = useBodyWidth()
  const isMediumWidth = isMedium(width)

  // Display large player on medium screens, as the grid layout cuts off the default medium player on medium screens
  // Large player needs to have 1:1 aspect ratio, so don't set height
  const size = responsiveSizing && isMediumWidth ? 'large' : 'medium'
  const height =
    !responsiveSizing || !isMediumWidth
      ? MEDIUM_BANDCAMP_PLAYER_HEIGHT
      : undefined

  const themeType = type === 'album' ? theme.bandcampAlbum : theme.bandcampTrack
  const nonHashedColor = removeHashFromColor(
    color || themeType.defaultColor || ''
  )
  const nonHashedBackgroundColor = removeHashFromColor(
    themeType.backgroundColor
  )

  return (
    <PlayerWidgetContainer
      data-testid={testIds.playerWidgetContainer}
      height={height}
      title={`Bandcamp ${capitalize(type)}: ${title}`}
      src={`https://bandcamp.com/EmbeddedPlayer/${type}=${sourceId}/size=${size}/bgcol=${nonHashedBackgroundColor}/linkcol=${nonHashedColor}/tracklist=true/transparent=true/`}
    />
  )
}

const MEDIUM_BANDCAMP_PLAYER_HEIGHT = 120

BandcampPlayer.propTypes = {
  sourceId: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  color: PropTypes.string,
  theme: PropTypes.shape({
    bandcampAlbum: PropTypes.shape({
      defaultColor: PropTypes.string.isRequired,
      backgroundColor: PropTypes.string.isRequired,
    }).isRequired,
    bandcampTrack: PropTypes.shape({
      defaultColor: PropTypes.string.isRequired,
      backgroundColor: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  responsiveSizing: PropTypes.bool,
  type: PropTypes.oneOf(['album', 'track']).isRequired,
}

BandcampPlayer.defaultProps = {
  responsiveSizing: true,
}

export default withTheme(BandcampPlayer)
