import { gql } from '@apollo/client'

const PRESALE_SIGN_UP = gql`
  mutation signupToPresale($input: PresaleUsersInput!) {
    signupToPresale(input: $input) {
      eventId
      signedUpAt
    }
  }
`

export default PRESALE_SIGN_UP
