import { useMutation } from '@apollo/client'
import EventDto from '@/interfaces/gql/EventDto'
import GET_EVENT_DETAIL from '../GetEventDetailQuery'
import PRESALE_SIGN_UP from './gql/PresaleSignUpMutation'

const usePresaleSignUp = (eventId) => {
  const [signUpForPresale] = useMutation(PRESALE_SIGN_UP, {
    update(cache, { data }) {
      const presaleEventData: {
        event: EventDto
      } = cache.readQuery({
        query: GET_EVENT_DETAIL,
        variables: {
          id: eventId,
          isAuthenticated: true,
          includeOffline: true,
          canAccessPresale: true,
        },
      })

      if (presaleEventData && data) {
        cache.writeQuery({
          query: GET_EVENT_DETAIL,
          variables: {
            id: eventId,
            isAuthenticated: true,
            includeOffline: true,
            canAccessPresale: true,
          },
          data: {
            event: {
              ...presaleEventData,
              isSignedUpToPresale: true,
            },
          },
        })
      }
    },
  })

  const handleSignUpPresale = async () => {
    const input = {
      eventId,
      signedUpAt: new Date(),
    }
    try {
      const result = await signUpForPresale({
        variables: {
          input,
        },
      })

      if (!result?.errors) {
        return { success: true }
      }
    } catch (error) {
      return { success: false }
    }
    return { success: false }
  }

  return { handleSignUpPresale }
}

export default usePresaleSignUp
