import { Text } from '@resident-advisor/design-system'
import styled from 'styled-components'
import ensureUnreachable from '@resident-advisor/utils/ensure-unreachable'
import {
  BrunchTicketType,
  BrunchVIPTicketType,
} from '../../helpers/BrunchTicketTypes'

const EXCLUSIVE_ENTRANCE = 'Exclusive entrance'
const VIP_AREA = 'VIP Area'
const VIP_BATHROOMS_AND_BARS = 'VIP Bathrooms + VIP Bars'
const STAGE_ACCESS_NOT_GUARANTEED = 'Does not guarantee stage access'

const AFTERPARTIES_NOT_INCLUDED = 'Afterparties NOT included'
const AFTERPARTY_NOT_INCLUDED = 'Afterparty NOT included'

const BrunchTicketTypeDescription = ({
  ticketType,
}: {
  ticketType: BrunchTicketType | BrunchVIPTicketType
}) => {
  switch (ticketType) {
    case BrunchTicketType.TWO_DAY:
      return (
        <BrunchEventDetailList>
          <li>
            <Text>Access: Friday 8 / Saturday 9 - August</Text>
          </li>
          <li>
            <Text>
              Locations: Friday 8 / Saturday 9 at Parc del Fòrum, Barcelona
            </Text>
          </li>
          <li>
            <Text>
              Opening Concert + Sunday Closing day events NOT included
            </Text>
          </li>
          <li>
            <Text>{AFTERPARTIES_NOT_INCLUDED}</Text>
          </li>
        </BrunchEventDetailList>
      )
    case BrunchTicketType.THREE_DAY:
      return (
        <BrunchEventDetailList>
          <li>
            <Text>Access: Friday 8 / Saturday 9 / Sunday 10 - August</Text>
          </li>
          <li>
            <Text>Locations: Friday 8 / Saturday 9 at Parc del Fòrum</Text>
          </li>
          <li>
            <Text>Locations: Sunday 10 at Poble Espanyol, Barcelona</Text>
          </li>
          <li>
            <Text>Opening Concert event NOT included</Text>
          </li>
          <li>
            <Text>{AFTERPARTIES_NOT_INCLUDED}</Text>
          </li>
        </BrunchEventDetailList>
      )
    case BrunchTicketType.THURSDAY:
      return (
        <BrunchEventDetailList>
          <li>
            <Text>Access: Thursday, August 7</Text>
          </li>
          <li>
            <Text>Locations: Poble Espanyol, Barcelona</Text>
          </li>
          <li>
            <Text>Time: 7PM to 11PM</Text>
          </li>
          <li>
            <Text>{AFTERPARTY_NOT_INCLUDED}</Text>
          </li>
        </BrunchEventDetailList>
      )
    case BrunchTicketType.FRIDAY:
      return (
        <BrunchEventDetailList>
          <li>
            <Text>Access: Friday, August 8</Text>
          </li>
          <li>
            <Text>Locations: Parc del Fòrum, Barcelona</Text>
          </li>
          <li>
            <Text>Time: 3PM to 1AM</Text>
          </li>
          <li>
            <Text>{AFTERPARTIES_NOT_INCLUDED}</Text>
          </li>
        </BrunchEventDetailList>
      )
    case BrunchTicketType.SATURDAY:
      return (
        <BrunchEventDetailList>
          <li>
            <Text>Access: Saturday, August 9</Text>
          </li>
          <li>
            <Text>Locations: Parc del Fòrum, Barcelona</Text>
          </li>
          <li>
            <Text>Time: 3PM to 3AM</Text>
          </li>
          <li>
            <Text>{AFTERPARTIES_NOT_INCLUDED}</Text>
          </li>
        </BrunchEventDetailList>
      )
    case BrunchTicketType.SUNDAY:
      return (
        <BrunchEventDetailList>
          <li>
            <Text>Access: Sunday, August 10</Text>
          </li>
          <li>
            <Text>Locations: Poble Espanyol, Barcelona</Text>
          </li>
          <li>
            <Text>Time: 4PM to 11AM</Text>
          </li>
          <li>
            <Text>{AFTERPARTY_NOT_INCLUDED}</Text>
          </li>
        </BrunchEventDetailList>
      )
    case BrunchVIPTicketType.THURSDAY_VIP:
    case BrunchVIPTicketType.SUNDAY_VIP:
      return (
        <BrunchEventDetailList>
          <li>
            <Text>{EXCLUSIVE_ENTRANCE}</Text>
          </li>
          <li>
            <Text>{VIP_AREA}</Text>
          </li>
          <li>
            <Text>{VIP_BATHROOMS_AND_BARS}</Text>
          </li>
          <li>
            <Text>{STAGE_ACCESS_NOT_GUARANTEED}</Text>
          </li>
          <li>
            <Text>{AFTERPARTY_NOT_INCLUDED}</Text>
          </li>
        </BrunchEventDetailList>
      )
    case BrunchVIPTicketType.TWO_DAY_VIP:
    case BrunchVIPTicketType.THREE_DAY_VIP:
    case BrunchVIPTicketType.FRIDAY_VIP:
    case BrunchVIPTicketType.SATURDAY_VIP:
      return (
        <BrunchEventDetailList>
          <li>
            <Text>{EXCLUSIVE_ENTRANCE}</Text>
          </li>
          <li>
            <Text>{VIP_AREA}</Text>
          </li>
          <li>
            <Text>{VIP_BATHROOMS_AND_BARS}</Text>
          </li>
          <li>
            <Text>{STAGE_ACCESS_NOT_GUARANTEED}</Text>
          </li>
          <li>
            <Text>{AFTERPARTIES_NOT_INCLUDED}</Text>
          </li>
        </BrunchEventDetailList>
      )
    default:
      return ensureUnreachable(ticketType)
  }
}

const BrunchEventDetailList = styled.ul`
  list-style-type: initial;
  color: white;
`

export default BrunchTicketTypeDescription
