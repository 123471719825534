import ensureUnreachable from '@resident-advisor/utils/ensure-unreachable'
import getConfig from 'next/config'
import { BrunchTicketType } from './BrunchTicketTypes'

const {
  publicRuntimeConfig: {
    BRUNCH_CONFIG: {
      BRUNCH_2_DAY_EVENT_ID,
      BRUNCH_2_DAY_VIP_EVENT_ID,
      BRUNCH_3_DAY_EVENT_ID,
      BRUNCH_3_DAY_VIP_EVENT_ID,
      BRUNCH_THURSDAY_EVENT_ID,
      BRUNCH_THURSDAY_VIP_EVENT_ID,
      BRUNCH_FRIDAY_EVENT_ID,
      BRUNCH_FRIDAY_VIP_EVENT_ID,
      BRUNCH_SATURDAY_EVENT_ID,
      BRUNCH_SATURDAY_VIP_EVENT_ID,
      BRUNCH_SUNDAY_EVENT_ID,
      BRUNCH_SUNDAY_VIP_EVENT_ID,
    },
  },
} = getConfig()

export const getBrunchEventIdsForTicketType = (
  ticketType: BrunchTicketType
): string[] => {
  switch (ticketType) {
    case BrunchTicketType.TWO_DAY:
      return [BRUNCH_2_DAY_EVENT_ID, BRUNCH_2_DAY_VIP_EVENT_ID]
    case BrunchTicketType.THREE_DAY:
      return [BRUNCH_3_DAY_EVENT_ID, BRUNCH_3_DAY_VIP_EVENT_ID]
    case BrunchTicketType.THURSDAY:
      return [BRUNCH_THURSDAY_EVENT_ID, BRUNCH_THURSDAY_VIP_EVENT_ID]
    case BrunchTicketType.FRIDAY:
      return [BRUNCH_FRIDAY_EVENT_ID, BRUNCH_FRIDAY_VIP_EVENT_ID]
    case BrunchTicketType.SATURDAY:
      return [BRUNCH_SATURDAY_EVENT_ID, BRUNCH_SATURDAY_VIP_EVENT_ID]
    case BrunchTicketType.SUNDAY:
      return [BRUNCH_SUNDAY_EVENT_ID, BRUNCH_SUNDAY_VIP_EVENT_ID]
    default:
      return ensureUnreachable(ticketType)
  }
}

export const getBrunchTicketTypeForEventId = (
  eventId: string
): BrunchTicketType | undefined => {
  switch (eventId) {
    case BRUNCH_2_DAY_EVENT_ID:
      return BrunchTicketType.TWO_DAY
    case BRUNCH_2_DAY_VIP_EVENT_ID:
      return BrunchTicketType.TWO_DAY
    case BRUNCH_3_DAY_EVENT_ID:
      return BrunchTicketType.THREE_DAY
    case BRUNCH_3_DAY_VIP_EVENT_ID:
      return BrunchTicketType.THREE_DAY
    case BRUNCH_THURSDAY_EVENT_ID:
      return BrunchTicketType.THURSDAY
    case BRUNCH_THURSDAY_VIP_EVENT_ID:
      return BrunchTicketType.THURSDAY
    case BRUNCH_FRIDAY_EVENT_ID:
      return BrunchTicketType.FRIDAY
    case BRUNCH_FRIDAY_VIP_EVENT_ID:
      return BrunchTicketType.FRIDAY
    case BRUNCH_SATURDAY_EVENT_ID:
      return BrunchTicketType.SATURDAY
    case BRUNCH_SATURDAY_VIP_EVENT_ID:
      return BrunchTicketType.SATURDAY
    case BRUNCH_SUNDAY_EVENT_ID:
      return BrunchTicketType.SUNDAY
    case BRUNCH_SUNDAY_VIP_EVENT_ID:
      return BrunchTicketType.SUNDAY
    default:
      return undefined
  }
}
