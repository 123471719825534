import { BrunchTicketType } from './BrunchTicketTypes'

export enum BrunchPassTypes {
  'weekend',
  'day',
}

export const BRUNCH_WEEKEND_PASSES: BrunchTicketType[] = [
  BrunchTicketType.THREE_DAY,
  BrunchTicketType.TWO_DAY,
]

export const BRUNCH_DAY_PASSES: BrunchTicketType[] = [
  BrunchTicketType.THURSDAY,
  BrunchTicketType.FRIDAY,
  BrunchTicketType.SATURDAY,
  BrunchTicketType.SUNDAY,
]
export const getBrunchTicketTypesForPass = (passType: BrunchPassTypes) => {
  if (passType === BrunchPassTypes.day) return BRUNCH_DAY_PASSES
  return BRUNCH_WEEKEND_PASSES
}

export const getBrunchPassType = (ticketType: BrunchTicketType) => {
  if (BRUNCH_DAY_PASSES.includes(ticketType)) return BrunchPassTypes.day
  return BrunchPassTypes.weekend
}
