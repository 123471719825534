const SET_TIMES_STATUS_TYPE = Object.freeze({
  none: 'NONE',
  set: 'SET',
  public: 'PUBLIC',
  passed: 'PASSED',
})

type SetTimesStatusType =
  typeof SET_TIMES_STATUS_TYPE[keyof typeof SET_TIMES_STATUS_TYPE]
export default SET_TIMES_STATUS_TYPE
export type { SetTimesStatusType }
