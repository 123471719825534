import PropTypes from 'prop-types'
import { useIntl } from 'react-intl'
import {
  Alignment,
  Detail,
  Text,
  Grid,
  Column,
  variants,
} from '@resident-advisor/design-system'
import { formatDistanceStrict } from 'date-fns'
import { toDateFormat } from 'lib/dateFormat'
import messages from '@/messages/events'
import Link from '@/components/generic/link'
import useDateFnsLocale from '@/hooks/useDateFnsLocale'
import { useEventManagementUrl } from '@/hooks/useEventManagementUrl'
import EventContactDetail from './EventContactDetail'

const EventAdditionalInfo = ({
  minimumAge,
  promotionalLinks,
  id,
  dateUpdated,
  title,
  startTime,
  admin,
  cost,
  newEventForm,
}) => {
  const intl = useIntl()
  const localeData = useDateFnsLocale()
  const eventManagementUrl = useEventManagementUrl({
    eventId: id,
    newEventForm,
  })

  return (
    <Grid
      mCols={2}
      mWrap
      sCols={1}
      gridTemplateAreas={{ s: sGTA, m: mGTA }}
      pt={{ s: 4, m: 5 }}
    >
      <Column area={Areas.Admin}>
        <Detail
          mb={2}
          color="grey.30"
          title={intl.formatMessage(messages.eventAdmin)}
        >
          {admin?.username && (
            <Text dataTestId="event-admin" variant={variants.text.small}>
              {admin?.username}
            </Text>
          )}
          <Link
            href={eventManagementUrl}
            underline
            variant={variants.text.small}
          >
            {intl.formatMessage(messages.updateThisEvent)}
          </Link>
        </Detail>
      </Column>
      {dateUpdated && (
        <Column area={Areas.Updated}>
          <Detail
            mb={2}
            color="grey.30"
            title={intl.formatMessage(messages.lastUpdated)}
          >
            <Text variant={variants.text.small}>
              {getDistanceInWords(toDateFormat(dateUpdated), localeData)}
            </Text>
          </Detail>
        </Column>
      )}
      {promotionalLinks.length > 0 && (
        <Column area={Areas.Promotional}>
          <Detail
            color="grey.30"
            title={intl.formatMessage(messages.promotionalLinks)}
          >
            <Alignment flexDirection="column">
              {promotionalLinks.map((link) => (
                <Link
                  key={link.url}
                  href={link.url}
                  underline
                  target="_blank"
                  rel="noopener"
                  variant={variants.text.small}
                >
                  {link.title}
                </Link>
              ))}
            </Alignment>
          </Detail>
        </Column>
      )}
      {cost && (
        <Column area={Areas.Cost}>
          <Alignment flexDirection="column">
            <Detail title={intl.formatMessage(messages.cost)}>
              <Text variant={variants.text.small} color="primary">
                {cost}
              </Text>
            </Detail>
          </Alignment>
        </Column>
      )}
      {minimumAge > 0 && (
        <Column area={Areas.Additional}>
          <Alignment flexDirection="column">
            <Detail title={intl.formatMessage(messages.minimumAge)}>
              <Text variant={variants.text.small} color="primary">
                {minimumAge}+
              </Text>
            </Detail>
          </Alignment>
        </Column>
      )}
      <Column area={Areas.Contact}>
        <EventContactDetail
          id={id}
          startTime={startTime}
          admin={admin}
          title={title}
        />
      </Column>
    </Grid>
  )
}

const getDistanceInWords = (dateUpdated, locale) => {
  const distanceInWords = formatDistanceStrict(
    toDateFormat(dateUpdated),
    new Date(),
    {
      addSuffix: true,
      locale,
    }
  )
  const distanceInWordsCapitalised =
    distanceInWords.substring(0, 1).toUpperCase() + distanceInWords.substring(1)
  return distanceInWordsCapitalised
}

const mGTA = `
  "admin updated"
  "promotional additional"
  "cost contact"
`

const sGTA = `
  "admin"
  "promotional"
  "updated"
  "additional"
  "cost"
  "contact"
`

const Areas = {
  Admin: 'admin',
  Updated: 'updated',
  Promotional: 'promotional',
  Additional: 'additional',
  Contact: 'contact',
  Cost: 'cost',
}

EventAdditionalInfo.propTypes = {
  minimumAge: PropTypes.number,
  promotionalLinks: PropTypes.arrayOf(
    PropTypes.shape({
      url: PropTypes.string.isRequired,
    })
  ),
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  dateUpdated: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.instanceOf(Date),
  ]),
  startTime: PropTypes.string.isRequired,
  admin: PropTypes.object,
  title: PropTypes.string.isRequired,
  cost: PropTypes.string,
  newEventForm: PropTypes.bool,
}

export default EventAdditionalInfo
