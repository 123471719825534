import { useRouter } from 'next/router'
import isValidId from 'lib/validId'
import EventDetail from '@/components/events/pages/event-detail'
import WithDefaultLayout from '@/components/generic/layouts/default'
import Throw404 from '@/components/generic/throw-404'
import ENTITY_TYPE from '@/enums/entity-type'

// Note: Queue-it is enabled for the event pages via the QUEUE_IT_ENABLED_PATHNAME_REGEX env variable.
// If you ever change the name/path of this file, ensure that the change is reflected there.
const Event = () => {
  // Matches: /events/[idOrCountry]
  const {
    query: { idOrCountry, ticketType },
  } = useRouter()

  if (!isValidId(idOrCountry))
    return <Throw404 entityType={ENTITY_TYPE.Event} />

  return <EventDetail id={idOrCountry} ticketType={ticketType} />
}

export default WithDefaultLayout(Event, { footerBottomPadding: 101 }) // add 101px padding for the sticky buy ticket bar on event detail pages
