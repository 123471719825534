import PropTypes from 'prop-types'
import {
  IconButton,
  WhatsAppIcon,
  variants,
} from '@resident-advisor/design-system'
import { WhatsappShareButton } from 'react-share'

const Whatsapp = ({ iconVariant, shareUrl }) => (
  <WhatsappShareButton url={shareUrl}>
    <IconButton as="div" variant={iconVariant} Icon={WhatsAppIcon} />
  </WhatsappShareButton>
)

Whatsapp.defaultProps = {
  iconVariant: variants.iconButton.primary,
}

Whatsapp.propTypes = {
  iconVariant: PropTypes.string,
  shareUrl: PropTypes.string.isRequired,
}

export default Whatsapp
