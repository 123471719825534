import React from 'react'
import {
  Alignment,
  variants,
  Box,
  Accordion,
  AccordionTitle,
  AccordionContent,
  Tag,
} from '@resident-advisor/design-system'
import BrunchTicketTypeDescription from './BrunchTicketTypeDescription/BrunchTicketTypeDescription'
import {
  BrunchTicketType,
  BrunchVIPTicketType,
} from '../helpers/BrunchTicketTypes'
import getBrunchTicketTypeTitle from '../helpers/BrunchTicketTypeTitles'

const BrunchTicketTypeTitle = ({
  ticketType,
  isSoldOut,
  isVip,
}: {
  ticketType: BrunchTicketType | BrunchVIPTicketType
  isSoldOut: boolean
  isVip: boolean
}) => {
  const getTicketTitle = () => {
    switch (ticketType) {
      case BrunchTicketType.THURSDAY:
        return 'Thursday opening concert ticket'
      case BrunchVIPTicketType.THURSDAY_VIP:
        return 'Thursday opening concert VIP ticket'
      default:
        return getBrunchTicketTypeTitle(ticketType)
    }
  }

  return (
    <Accordion>
      <AccordionTitle
        withBorder={false}
        textVariant={variants.text.heading.m}
        buttonColor="primary"
      >
        <Alignment flexGrow={1} justifyContent="space-between" mr={2}>
          {getTicketTitle()}
          <Alignment justifyContent="space-between" style={{ gap: '10px' }}>
            {isVip && (
              <Tag variant={Tag.variant.gradientOutline}>VIP access</Tag>
            )}
            {isSoldOut && <Tag variant={Tag.variant.primary}>Sold out</Tag>}
          </Alignment>
        </Alignment>
      </AccordionTitle>
      <AccordionContent>
        <Box ml={3} mb={3}>
          <BrunchTicketTypeDescription ticketType={ticketType} />
        </Box>
      </AccordionContent>
    </Accordion>
  )
}

export default BrunchTicketTypeTitle
