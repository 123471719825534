import { useEffect } from 'react'
import { useRouter } from 'next/router'
import { setCookie } from 'lib/cookieHelpers'
import cookieNames from 'lib/cookieNames'
import featureSwitches from '@/enums/feature-switches'
import ThirdPartyAffiliate from '@/enums/third-party-affiliate'
import { useFeatureSwitch } from '@/context/FeatureSwitchesContext'

const useThirdPartyAffiliateCookie = (eventId) => {
  const { query } = useRouter()
  const enableNewThirdPartyAffiliateCookie = useFeatureSwitch(
    featureSwitches.enableNewThirdPartyAffiliateCookie
  )

  useEffect(() => {
    const { sharedId, subId1, subId3, partnerId } = query
    // When these query params are present, we know the event click has come via Spotify.
    // We're unable to request URL updates from them (we'd prefer 'spotify' as the partnerId
    // - currently they use 'residentadvisor') so must treat this as a special case.
    if (enableNewThirdPartyAffiliateCookie) {
      if (eventId && partnerId) {
        setCookie(
          cookieNames.affiliate,
          JSON.stringify({
            thirdPartyAffiliate:
              partnerId === 'residentadvisor' ? 'spotify' : partnerId,
            eventId,
            partnerId,
            ...(sharedId && { sharedId }),
            ...(subId1 && { subId1 }),
            ...(subId3 && { subId3 }),
          })
        )
      }
    } else {
      if (eventId && sharedId && subId1 && subId3 && partnerId) {
        setCookie(
          cookieNames.spotify,
          JSON.stringify({
            eventId,
            sharedId,
            subId1,
            subId3,
            partnerId,
          })
        )
      }

      if (eventId && partnerId === ThirdPartyAffiliate.soundcloud) {
        setCookie(
          cookieNames.soundcloud,
          JSON.stringify({
            eventId,
            partnerId,
          })
        )
      }
    }
  }, [enableNewThirdPartyAffiliateCookie, eventId, query])
}

export default useThirdPartyAffiliateCookie
