/**
 * Opens a deep link to an order in a mobile application and sets a fallback redirect URL if the deep link fails.
 *
 * This function attempts to open a given deep link URL immediately in a mobile app. If the deep link does not
 * result in a redirect within a short period (25 milliseconds), it redirects the user to the specified fallback URL,
 * which corresponds to the appropriate app store based on the detected operating system (iOS or Android).
 *
 * @param {string} url - The deep link URL to be opened based on OS detection.
 * @param {string} redirectUrl - The fallback URL to redirect to if the deep link fails (App Store or Google Play Store URL).
 */
const openDeepLink = (url: string, redirectUrl: string) => {
  const now = new Date().valueOf()

  setTimeout(() => {
    if (new Date().valueOf() - now > 1000) return
    window.location.replace(redirectUrl)
  }, 25)

  window.location.replace(url)
}

export default openDeepLink
