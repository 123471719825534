import ensureUnreachable from '@resident-advisor/utils/ensure-unreachable'
import { BrunchTicketType, BrunchVIPTicketType } from './BrunchTicketTypes'

const getBrunchTicketTypeTitle = (
  ticketType: BrunchTicketType | BrunchVIPTicketType
): string => {
  switch (ticketType) {
    case BrunchTicketType.TWO_DAY:
      return '2 day pass'
    case BrunchVIPTicketType.TWO_DAY_VIP:
      return '2 day pass VIP'
    case BrunchTicketType.THREE_DAY:
      return '3 day pass'
    case BrunchVIPTicketType.THREE_DAY_VIP:
      return '3 day pass VIP'
    case BrunchTicketType.THURSDAY:
      return 'Thursday'
    case BrunchVIPTicketType.THURSDAY_VIP:
      return 'VIP Thursday'
    case BrunchTicketType.FRIDAY:
      return 'Friday'
    case BrunchVIPTicketType.FRIDAY_VIP:
      return 'VIP Friday'
    case BrunchTicketType.SATURDAY:
      return 'Saturday'
    case BrunchVIPTicketType.SATURDAY_VIP:
      return 'VIP Saturday'
    case BrunchTicketType.SUNDAY:
      return 'Sunday'
    case BrunchVIPTicketType.SUNDAY_VIP:
      return 'VIP Sunday'
    default:
      return ensureUnreachable(ticketType)
  }
}

export default getBrunchTicketTypeTitle
