import { defineMessages, useIntl } from 'react-intl'
import { Alignment, Text, variants } from '@resident-advisor/design-system'
import PropTypes from 'prop-types'

const messages = defineMessages({
  listenTo: {
    id: 'Dict.ListenTo',
    defaultMessage: 'Listen to it via your preferred platform',
  },
})

const ListenTo = ({ children }) => {
  const intl = useIntl()

  return (
    <Alignment flexDirection="column" alignItems="flex-start">
      <Text color="secondary" variant={variants.text.label} my={3}>
        {intl.formatMessage(messages.listenTo)}
      </Text>

      {children}
    </Alignment>
  )
}

ListenTo.propTypes = {
  children: PropTypes.node,
}

export default ListenTo
