import { Box } from '@resident-advisor/design-system'
import { useState, useEffect } from 'react'
import ReactPlayer from 'react-player/lazy' // Lazy load media players to reduce bundle size https://github.com/resident-advisor/ra-next/pull/2911/files
import ASPECT_RATIO from 'lib/aspectRatio'
import testIds from '@/enums/testIds'

const MediaPlayer = ({
  url,
  playerOptions,
  height = '100%',
}: {
  url: string
  playerOptions?: Record<string, boolean>
  height?: string | number
}) => {
  const [canPlay, setCanPlay] = useState(false)

  useEffect(() => {
    setCanPlay(ReactPlayer.canPlay(url))
  }, [url])

  if (!canPlay) {
    return null
  }

  return (
    <Box
      data-testid={testIds.mediaPlayer}
      width="100%"
      pb={ASPECT_RATIO['16:9']}
      position="relative"
    >
      <ReactPlayer
        url={url}
        config={{
          soundcloud: {
            options: {
              visual: true,
              show_teaser: true,
              show_comments: true,
              show_user: true,
              hide_related: false,
              show_reposts: false,
              ...playerOptions,
            },
          },
        }}
        style={{ position: 'absolute', top: 0, left: 0 }}
        height={height}
        width="100%"
      />
    </Box>
  )
}

export default MediaPlayer
