import PropTypes from 'prop-types'
import { Alignment } from '@resident-advisor/design-system'
import { DefaultLoadingSpinner } from '@/components/generic/query-result-handler'
import ListenTo from '@/components/generic/listen-to'
import audioServices, { convertServiceToIcon } from '@/enums/audio-services'
import useBuildAudioPlayerUrls from './useBuildAudioPlayerUrls'

import PlayerButton from './PlayerButton'

const ExternalAudioButtons = ({ players }) => {
  const { externalUrls, loading } = useBuildAudioPlayerUrls(players)

  if (loading) return <DefaultLoadingSpinner />

  const filteredPlayers = players.filter(
    (player) =>
      !!externalUrls[player.audioService.id] &&
      parseInt(player.audioService.id) !== audioServices.BANDCAMP_TRACK &&
      parseInt(player.audioService.id) !== audioServices.BANDCAMP_ALBUM
  )

  if (filteredPlayers.length === 0) {
    return null
  }

  return (
    <ListenTo>
      <Alignment flexDirection="column" alignItems="flex-start">
        {filteredPlayers.map((player) => (
          <PlayerButton
            key={player.sourceId}
            Icon={convertServiceToIcon(player.audioService)}
            href={externalUrls[player.audioService.id]}
          >
            {player.audioService.name}
          </PlayerButton>
        ))}
      </Alignment>
    </ListenTo>
  )
}

ExternalAudioButtons.propTypes = {
  players: PropTypes.array,
}

export default ExternalAudioButtons
