import { useEffect, useRef } from 'react'
import AD_TARGETING_KEYS from '@/enums/ad-targeting-keys'
import { useAdTargetingContext, actions } from '@/context/AdTargetingContext'

const useAdTargetingForUser = (user = {}) => {
  useEffect(() => {
    clearAll()

    if (window?.googletag?.pubads && user?.adTargeting) {
      user.adTargeting.forEach((adTargeting) => {
        window.googletag
          .pubads()
          .setTargeting(
            adTargeting.targetId.toString(),
            adTargeting.campaignCode
          )
      })
    }

    if (window?.googletag?.pubads && user?.managedProfiles) {
      if (user.managedProfiles.hasArtists)
        window.googletag.pubads().setTargeting(AD_TARGETING_KEYS.IS_ARTIST, '1')
      if (user.managedProfiles.hasPromoters)
        window.googletag
          .pubads()
          .setTargeting(AD_TARGETING_KEYS.IS_PROMOTER, '1')
      if (user.managedProfiles.hasLabels)
        window.googletag.pubads().setTargeting(AD_TARGETING_KEYS.IS_LABEL, '1')
    }
  }, [user])
}

const useSetAdTargeting = (payload) => {
  const { dispatch } = useAdTargetingContext()
  const payloadRef = useRef(payload)

  useEffect(() => {
    dispatch({
      type: actions.SET_TARGETING,
      payload: payloadRef.current,
    })
  }, [dispatch])
}

const clearAll = () => {
  if (window?.googletag?.pubads) {
    window.googletag.pubads().clearTargeting()
  }
}

export { useAdTargetingForUser, useSetAdTargeting as useAdTargetingDispatch }
