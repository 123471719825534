import React, { FC } from 'react'
import {
  colors,
  Icon,
  Alignment,
  Text,
  variants,
  RAIcon,
} from '@resident-advisor/design-system'
import { useIntl } from 'react-intl'
import payment from '@/messages/payment'
import events from '@/messages/events'
import { Bold } from '@/messages/formatters'
import EventDateRange from '@/components/shared/event-date-range'
import { useUserContext } from '@/context/UserContext'

type Props = {
  title: string
  venue: string
  startTime: Date | string
  endTime: Date | string
}

const OrderInfo: FC<Props> = ({ venue, title, startTime, endTime }) => {
  const intl = useIntl()
  const { user } = useUserContext()

  return (
    <>
      <Alignment mt="20px" flexDirection="column" style={{ gap: 8 }}>
        <Icon color={colors.white} Component={RAIcon} width={33} />

        <Text variant={variants.text.heading.l}>
          {intl.formatMessage(payment.youGotTickets, {
            name: user.firstName || user.email,
          })}
        </Text>

        <Text variant={variants.text.body}>
          {intl.formatMessage(payment.confirmationSentToEmail, {
            email: user.email,
            bold: Bold,
          })}
        </Text>
      </Alignment>

      <Alignment mt={30} flexDirection="column" style={{ gap: 10 }}>
        <Alignment flexDirection="column">
          <Text variant={variants.text.tag} color={colors.grey['50']}>
            {intl.formatMessage(events.event)}
          </Text>

          <Text variant={variants.text.body}>{title}</Text>
        </Alignment>

        <Alignment flexDirection="column">
          <Text variant={variants.text.tag} color={colors.grey['50']}>
            {intl.formatMessage(events.venue)}
          </Text>

          <Text variant={variants.text.body}>{venue}</Text>
        </Alignment>

        <Alignment flexDirection="column">
          <Text variant={variants.text.tag} color={colors.grey['50']}>
            {intl.formatMessage(events.date)}
          </Text>

          <EventDateRange
            startTime={startTime}
            endTime={endTime}
            variant={variants.text.body}
          />
        </Alignment>
      </Alignment>
    </>
  )
}

export default OrderInfo
